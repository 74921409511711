import { IResult } from './Utils'
import { regionalApiServer } from './Endpoints'

export const enum FormDeployContextEnum {
  Resource = 'resource',
  Job = 'job'
}

export interface IMobileV2Form {
  id: string
  name: string
  formRev: IFormRev
  jobTypes: string[]
  orderIndex: number
  isShownInMobileV3: boolean
}

export interface IFormRev {
  id: string
  formId: string
  version: number
  definition: FormDefinition
  files: string[]
}

interface FormDefinition {
  forms: FormDefinitionFormItem[]
  jobTypes: any[]
  deploy: FormDefinitionDeploy
  meta: FormDefinitionMeta
}

interface FormDefinitionMeta {
  sdkVersion: number
}

interface FormDefinitionDeploy {
  context: string
}

interface FormDefinitionFormItem {
  name: string
  root: string
}

export interface IUpdateShowInV3Item {
  formId: string
  show: boolean
}

export type IUpdateShowInV3Payload = IUpdateShowInV3Item[]

export const getMobileV2FormsByTenantId = (tenantId: string, region: string) =>
  regionalApiServer(region)
    .get<IResult<IMobileV2Form[]>>(`/internal/customform/form/${tenantId}`)
    .then(response => response.data.result)

export const updateShowInMobileV3Flag = (payload: IUpdateShowInV3Payload, tenantId: string, region: string) =>
  regionalApiServer(region)
    .put<IResult<IMobileV2Form[]>>(`/internal/customform/form/show_in_v3/${tenantId}`, payload)
    .then(response => response.data.result)
