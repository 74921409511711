import { Segment, Table, SemanticWIDTHS } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { TableLoaderWrap } from '../../../tableComponents/TableLoader'
import { RenderEditableTableHeader } from '../../../tableComponents/editableTableComponents/EditableTableHeader'
import { useGetTeamQuery } from '../../../../queries/GetTeamQuery'
import { useMobileV2FormService } from '../../services/MobileV2FormService'
import { IMobileV2Form, IUpdateShowInV3Payload } from '../../../../actions/MobileV2Forms'
import { MobileV2FormRow } from './MobileV2FormRow'

export const EMPTY_LIST_MESSAGE = 'No mobile v2 extensions found.'
const FORM_IN_PACKAGE_COLUMN_WIDTH: SemanticWIDTHS = 3
const REVISION_ID_COLUMN_WIDTH: SemanticWIDTHS = 2
const FORM_TYPE_COLUMN_WIDTH: SemanticWIDTHS = 2

const getShowInV3Map = (forms: IMobileV2Form[]): Map<string, boolean> => {
  const map = new Map<string, boolean>()
  forms.forEach(form => {
    map.set(form.id, form.isShownInMobileV3 ?? false)
  })
  return map
}

interface MobileV2FormsProps {
  teamId: string
}

const MobileV2Forms = ({ teamId }: MobileV2FormsProps) => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { mobileV2Forms, isFormListLoading, updateShowInV3FlagAsync } = useMobileV2FormService({
    tenantId: teamData?.tenantId ?? '',
    region: teamData?.region ?? ''
  })

  const [editing, setEditing] = useState(false)
  const [pushing, setPushing] = useState(false)
  const [localShowInV3Map, setLocalShowInV3Map] = useState<Map<string, boolean>>(new Map())

  useEffect(() => {
    if (isFormListLoading || loadingTeamData) {
      return
    }
    setLocalShowInV3Map(getShowInV3Map(mobileV2Forms))
  }, [mobileV2Forms])

  const handleSubmit = async () => {
    setPushing(true)
    const payload: IUpdateShowInV3Payload = []
    for (const [key, value] of localShowInV3Map.entries()) {
      payload.push({
        formId: key,
        show: value
      })
    }
    await updateShowInV3FlagAsync(payload)
    setEditing(false)
    setPushing(false)
  }

  const handleStartEdit = () => {
    setEditing(prevState => !prevState)
  }

  const handleCancel = () => {
    setEditing(false)
    setLocalShowInV3Map(getShowInV3Map(mobileV2Forms))
  }

  const handleShowInV3Change = (form: IMobileV2Form, value: boolean) => {
    setLocalShowInV3Map(map => {
      const updatedMap = new Map(map)
      updatedMap.set(form.id, value)
      return updatedMap
    })
  }

  const haveDataChanged = () => {
    const remoteMap = getShowInV3Map(mobileV2Forms)
    return !isEqual(remoteMap, localShowInV3Map)
  }

  return (
    <div
      data-testid="mobile-v2-forms-list"
      style={{
        marginTop: '4rem'
      }}
    >
      <RenderEditableTableHeader
        editing={editing}
        isEditable={true}
        loading={isFormListLoading || loadingTeamData}
        reset={handleCancel}
        submit={handleSubmit}
        submitting={pushing}
        tableTitle="Mobile v2 extensions"
        tableSubtitle="This section shows the list of custom v2 forms in a team. A package can include one or multiple forms with the same type and settings"
        toggleEditing={handleStartEdit}
        validateSubmit={haveDataChanged()}
      />
      <Segment color="blue" style={{ flexGrow: 0 }}>
        <Table celled basic="very" stackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={FORM_IN_PACKAGE_COLUMN_WIDTH}>Forms in package</Table.HeaderCell>
              <Table.HeaderCell width={REVISION_ID_COLUMN_WIDTH}>Revision ID</Table.HeaderCell>
              <Table.HeaderCell width={FORM_TYPE_COLUMN_WIDTH}>Form type</Table.HeaderCell>
              <Table.HeaderCell>Job type</Table.HeaderCell>
              <Table.HeaderCell>Show in V3</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <TableLoaderWrap
            loading={isFormListLoading || loadingTeamData}
            array={mobileV2Forms}
            emptyMessage={EMPTY_LIST_MESSAGE}
          >
            <Table.Body>
              {mobileV2Forms?.map(form => (
                <MobileV2FormRow
                  key={form.id}
                  form={form}
                  editing={editing}
                  localShowInV3Map={localShowInV3Map}
                  handleShowInV3Change={handleShowInV3Change}
                />
              ))}
            </Table.Body>
          </TableLoaderWrap>
        </Table>
      </Segment>
    </div>
  )
}

export default MobileV2Forms
