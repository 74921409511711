import { DatadogLogsButton } from '../../../components/DatadogButtons'

export const DatadogPackageLogsButton = (props: {
  tenantId: string
  deploymentId: string
  packageName: string
  startTime?: number
  endTime?: number
  title?: string
}) => {
  const query = encodeURIComponent(`service:artifact-api "DEPLOY(${props.tenantId}/${props.deploymentId})"`)
  return (
    <DatadogLogsButton
      startTime={props.startTime}
      endTime={props.endTime}
      url={`https://app.datadoghq.com/logs?query=${query}`}
      altText={`Datadog installation logs for ${props.packageName}`}
      title={props.title}
    />
  )
}
