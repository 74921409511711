import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button, Grid, Header } from 'semantic-ui-react'
import { Team, TeamWithModel } from '../../../actions/NewTeams'
import { IUserSummary } from '../../../actions/Tenants'
import { VendorClaim } from '../../../actions/Utils'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { useGetUsersReportForTeamsQuery } from '../../../queries/GetUsersReportForTeamsQuery'
import { getTeamsList } from '../services/CustomerTeamsService'
import { salesforceWarning } from '../../tenants/components/Users/UsersService'
import { UserCounts } from '../../../views/tenants/components/Users/UserCounts'
import { CustomerUsersList } from './CustomerUsersList'
import { CustomerAccountData } from './CustomerAccountData'

export interface TeamWithSummary {
  team: TeamWithModel
  summary: IUserSummary
}

export interface TeamWithApi {
  team: Team
  api: string
}

interface CsvData {
  TeamName: string
  Environment: string
  ActiveResources: number | null
  ActiveSchedulers: number | null
}

export const getTotals = (data: TeamWithSummary[]): IUserSummary[] => {
  const productionTeams = data
    .filter(teamWithSummary => teamWithSummary.team.environment === 'Production')
    .map(team => team.summary)
  const resources = productionTeams.reduce((partialSum, summary) => partialSum + (summary.totalActiveResources || 0), 0)
  const schedulers = productionTeams.reduce(
    (partialSum, summary) => partialSum + (summary.totalActiveSchedulers || 0),
    0
  )

  const productionTotal = { tenantId: '', totalActiveResources: resources, totalActiveSchedulers: schedulers }
  return [productionTotal]
}

const getCsvData = (data: TeamWithSummary[] | undefined) => {
  const csvData: CsvData[] | undefined = data?.map(team => ({
    TeamName: team.team.primaryName,
    Environment: team.team.environment,
    ActiveResources: team.summary.totalActiveResources,
    ActiveSchedulers: team.summary.totalActiveSchedulers
  }))
  return csvData || []
}

export const checkHasAnySalesforceTeams = (teamsList: Team[]) => teamsList.filter(team => team.isSalesforce).length > 0

export const CustomerUsers = ({ customerId }: { customerId: string }) => {
  const teamsList = getTeamsList(customerId)

  const { data: regionsInfo, isFetching: isFetchingRegions } = useGetRegionQuery()
  const { data: summaryData, isFetching } = useGetUsersReportForTeamsQuery(teamsList || [], regionsInfo || [])

  const [totalsData, setTotalsData] = useState<IUserSummary[]>([])
  useEffect(() => setTotalsData(getTotals(summaryData || [])), [summaryData])

  const [csvData, setCsvData] = useState<CsvData[]>([])
  useEffect(() => setCsvData(getCsvData(summaryData)), [summaryData])

  return (
    <div>
      <CustomerAccountData customerId={customerId} contract />
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <Header as="h3">Users</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <CSVLink data={csvData || []} filename={'user_list_' + customerId}>
            <Button className={'form-button-sked-blue'} content={'Export to CSV'} />
          </CSVLink>
        </Grid.Column>
      </Grid>
      {salesforceWarning(true, checkHasAnySalesforceTeams(teamsList) ? VendorClaim.Salesforce : VendorClaim.Skedulo)}
      {totalsData && totalsData.length > 0 && (
        <UserCounts data={totalsData} loading={isFetchingRegions || isFetching} isForCustomer />
      )}
      <CustomerUsersList data={summaryData} loading={isFetchingRegions || isFetching}></CustomerUsersList>
    </div>
  )
}
