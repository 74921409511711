import { Row } from 'react-table'
import { Link } from 'react-router-dom'
import { Label, SemanticCOLORS } from 'semantic-ui-react'
import { RegionFlagPopup } from '../../../utils/regionFlags'
import { createMemoizedColumns } from '../../../components/TableComponents/ReactTable'
import {
  getValuesFromDynamoQuery,
  InfinitePaginatedTable
} from '../../../components/TableComponents/InfinitePaginatedTable'
import { Team } from '../../../actions/NewTeams'
import { TeamsService } from '../services/TeamsService'
import { formatDate } from '../../../utils/dateUtils'
import { addSalesforceIcon } from './NewTeamNamesList'

interface EnvironmentColours {
  [key: string]: SemanticCOLORS
}

export const environmentColours: EnvironmentColours = {
  Demo: 'purple',
  Development: 'orange',
  Production: 'green',
  Test: 'grey',
  Training: 'pink',
  UAT: 'blue'
}

export interface NewTeamListProps {
  teamsService: TeamsService
  showCustomerId: boolean
  isKeywordSearch: boolean
}

export const getTeamsColumns = (showCustomerId: boolean, hideExcessColumns?: boolean) => {
  const teamsColumns = createMemoizedColumns<Team>([
    {
      Header: hideExcessColumns ? 'Team name' : 'Name',
      accessor: 'primaryName',
      Cell: ({ row }: { row: Row<Team> }) => (
        <Link to={`/teams/${row.original.id}`}>
          {row.original.primaryName}
          {addSalesforceIcon(row.original.isSalesforce)}
        </Link>
      )
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ row }: { row: Row<Team> }) => {
        const description = row.original.description
        return <>{description.length > 50 ? description.substring(0, 50) + '...' : description}</>
      }
    },
    {
      Header: 'Region',
      accessor: 'region',
      Cell: ({ row }: { row: Row<Team> }) => <RegionFlagPopup region={row.original.region} />
    },
    {
      Header: 'Tenant ID',
      accessor: 'tenantId',
      Cell: ({ row }: { row: Row<Team> }) => <div className="table-cell-softwrap">{row.original.tenantId}</div>
    },
    {
      Header: 'Owner',
      accessor: 'owner'
    },
    {
      Header: 'Environment',
      accessor: 'environment',
      Cell: ({ row }: { row: Row<Team> }) => (
        <Label color={environmentColours[row.original.environment] ?? 'grey'} basic>
          {row.original.environment}
        </Label>
      )
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ row }: { row: Row<Team> }) => <>{formatDate(row.original.createdAt)}</>
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ row }: { row: Row<Team> }) => <>{formatDate(row.original.updatedAt)}</>
    }
  ])

  const excludeColumns = hideExcessColumns ? ['createdAt', 'updatedAt'] : []
  const filteredColumns = teamsColumns.filter(column => !excludeColumns.some(accessor => accessor === column.accessor))

  if (showCustomerId) {
    filteredColumns.push({
      Header: 'Customer ID',
      accessor: 'customerId',
      Cell: ({ row }: { row: Row<Team> }) => (
        <Link to={`/customers/${row.original.customerId}`}>{row.original.customerId.substring(0, 10)}...</Link>
      )
    })
  }

  return filteredColumns
}

export const NewTeamsList = ({ teamsService, showCustomerId, isKeywordSearch }: NewTeamListProps) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = teamsService
  const teamsColumns = getTeamsColumns(showCustomerId)

  return (
    <InfinitePaginatedTable
      loading={isLoading}
      columns={teamsColumns}
      data={data}
      emptyMessage={`No teams found ${isKeywordSearch ? '' : '(search is case sensitive)'}`}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      fetchingNextPage={isFetchingNextPage}
      getValuesFromData={getValuesFromDynamoQuery}
      scrollable
    />
  )
}
