import * as React from 'react'
import { Checkbox, CheckboxProps, Label, Segment, Table } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { featureFlagNameToKebabCase, featureFlagNameToSentenceCase } from '../../../views/newTeams/NewFeatureFlagReport'
import { IFeatureFlags, IFeatureFlagWithDescription } from '../../../actions/Tenants'
import { RenderEditableTableHeader } from '../../tableComponents/editableTableComponents/EditableTableHeader'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { toNameAndValueMap } from './TenantFeatures'

const TenantFeature = (props: {
  details: IFeatureFlagWithDescription
  localValue: boolean
  editable: boolean
  hasStatusColumn: boolean
  hasDefaultColumn: boolean
  handleFeaturesSettingChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void
}) => {
  const { details, localValue, editable } = props
  const status = details.status === 'GeneralAvailability' ? 'General Availability' : details.status
  const isDefault = details.defaultValue === details.value
  return (
    <Table.Row data-testid={details.name} key={details.name} className="feature">
      <Table.Cell>
        {
          <Link to={`/feature-flag-report/${featureFlagNameToKebabCase(details.name)}`}>
            {featureFlagNameToSentenceCase(details.name)}
          </Link>
        }
      </Table.Cell>
      <Table.Cell>{details.description}</Table.Cell>
      {props.hasStatusColumn && <Table.Cell>{status}</Table.Cell>}
      <Table.Cell>{details.platform === 'Skedulo' ? 'Pulse' : details.platform}</Table.Cell>
      {props.hasDefaultColumn && (
        <Table.Cell>
          {isDefault ? (
            ''
          ) : (
            <Label basic color="blue">
              Non-default
            </Label>
          )}
        </Table.Cell>
      )}
      <Table.Cell>
        <Checkbox
          toggle
          checked={localValue}
          onChange={props.handleFeaturesSettingChange}
          name={details.name}
          disabled={!editable}
          readOnly={!editable}
        />
      </Table.Cell>
    </Table.Row>
  )
}

interface IProps {
  tenantId: string
  editable: boolean
  featuresWithDescriptions: IFeatureFlagWithDescription[]
  handleSubmit: (features: IFeatureFlags, successCallback: () => void) => void
  loading: boolean
  title: string
  subtitle: string
  hasStatusColumn: boolean
  hasDefaultColumn: boolean
}

export const TenantFeaturesTable = (props: IProps) => {
  const [editing, setEditing] = useState(false)
  const [pushing, setPushing] = useState(false)

  const remoteFeatures = toNameAndValueMap(props.featuresWithDescriptions || new Map())
  const [localFeatures, setLocalFeatures] = useState<IFeatureFlags>(new Map())
  const [localFeaturesTenantId, setLocalFeaturesTenantId] = useState('')

  useEffect(() => {
    if (props.featuresWithDescriptions.length > 0 && localFeaturesTenantId !== props.tenantId) {
      setLocalFeaturesTenantId(props.tenantId)
      setLocalFeatures(toNameAndValueMap(props.featuresWithDescriptions))
    }
  }, [localFeaturesTenantId, props.featuresWithDescriptions, setLocalFeatures])

  const handleFeaturesSettingChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (data.name) {
      setLocalFeatures(prevState => {
        const updatedLocalFeatures = new Map(prevState)
        updatedLocalFeatures.set(data.name as string, data.checked!)
        return updatedLocalFeatures
      })
    }
  }

  const resetAllFeatures = () => {
    setLocalFeatures(remoteFeatures)
  }

  const handleSubmit = async () => {
    // return the feature flags that have changed
    setPushing(true)
    const changedFeatures: IFeatureFlags = new Map()
    for (const [key, value] of localFeatures.entries()) {
      if (value !== remoteFeatures.get(key)) {
        changedFeatures.set(key, value)
      }
    }
    props.handleSubmit(changedFeatures, () => setEditing(false))
    setPushing(false)
  }

  const handleStartEdit = () => {
    setEditing(prevState => !prevState)
  }

  const handleCancel = () => {
    setEditing(false)
    resetAllFeatures()
  }

  const haveFeaturesChanged = () => {
    for (const [key, value] of localFeatures.entries()) {
      if (remoteFeatures.get(key) !== value) {
        return true
      }
    }
    return false
  }

  return (
    <div aria-label={props.title}>
      <RenderEditableTableHeader
        editing={editing}
        isEditable={props.editable}
        loading={props.loading}
        reset={handleCancel}
        submit={handleSubmit}
        submitting={pushing}
        tableTitle={props.title}
        tableSubtitle={props.subtitle}
        toggleEditing={handleStartEdit}
        validateSubmit={haveFeaturesChanged()}
      />
      <Segment color="blue" style={{ flexGrow: 0 }}>
        <Table celled basic="very" stackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Name</Table.HeaderCell>
              <Table.HeaderCell width={8}>Description</Table.HeaderCell>
              {props.hasStatusColumn && <Table.HeaderCell width={2}>Status</Table.HeaderCell>}
              <Table.HeaderCell width={1}>Platform</Table.HeaderCell>
              {props.hasDefaultColumn && <Table.HeaderCell width={2} />}
              <Table.HeaderCell width={1}>Enabled</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <TableLoaderWrap
            loading={props.loading}
            array={props.featuresWithDescriptions}
            emptyMessage="No feature flags loaded. This tenant might not be initialized."
          >
            <Table.Body>
              {
                // local features will be defined here
                localFeatures &&
                  props.featuresWithDescriptions?.map(feature => (
                    <TenantFeature
                      key={feature.name}
                      details={feature}
                      localValue={localFeatures.get(feature.name) ?? false}
                      editable={editing && props.editable}
                      hasStatusColumn={props.hasStatusColumn}
                      hasDefaultColumn={props.hasDefaultColumn}
                      handleFeaturesSettingChange={handleFeaturesSettingChange}
                    />
                  ))
              }
            </Table.Body>
          </TableLoaderWrap>
        </Table>
      </Segment>
    </div>
  )
}
