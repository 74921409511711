import { Grid, Header, Label, Message, SemanticCOLORS } from 'semantic-ui-react'
import { ISalesforceAccountWithContracts } from '../../../actions/SalesforceAccount'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'

export const renderRow = (title: string, component: JSX.Element): JSX.Element => (
  <Grid.Row>
    <Grid.Column textAlign="left" verticalAlign="top" style={{ flex: 1 }}>
      <Header as="h4" style={{ fontWeight: 600 }}>
        {title}
      </Header>
    </Grid.Column>
    <Grid.Column textAlign="left" verticalAlign="top" style={{ flex: 3 }}>
      <Header as="h4" style={{ fontWeight: 500 }}>
        {component}
      </Header>
    </Grid.Column>
  </Grid.Row>
)

export const renderItemStringWithBullet = (itemString: string | null): JSX.Element =>
  itemString ? (
    <ul role="list" style={{ margin: 0 }}>
      {itemString.split(';').map((item, _) => (
        <li aria-label={item} key={item}>
          {item}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  )

export const mapColorToSemanticColor = (color: string): SemanticCOLORS => {
  switch (color) {
    case 'Gray':
      return 'grey'
    case 'Red':
      return 'red'
    case 'Yellow':
      return 'yellow'
    case 'Green':
      return 'green'
    default:
      return 'grey'
  }
}

export const mapColorToAccountStatus = (type: string): SemanticCOLORS => {
  switch (type) {
    case 'Customer':
      return 'green'
    case 'Churn':
      return 'grey'
    case 'Prospect':
      return 'blue'
    default:
      return 'blue'
  }
}

/**
 * Format date to MMM DD, YYYY from Date String
 *
 * For example:
 * 2023-02-02 -> Feb 2, 2023
 */
export const formatDate = (inputDate: string | null): string => {
  if (inputDate) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const date = new Date(inputDate)

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }
  return ''
}

/**
 * Format CustomerHappinessNotes to replace \r\n with <br />
 */
export const formatCustomerHappinessNotes = (inputNotes: string | null): JSX.Element => {
  if (inputNotes) {
    const paragraphs = inputNotes.split('\r\n').map((note, index) => (
      <div key={index}>
        {note}
        {index > 0 ? <br /> : ''}
      </div>
    ))
    return <>{paragraphs}</>
  }
  return <></>
}

export const formatCurrency = (amount: number | null | undefined, currency: string | null | undefined): string => {
  if (amount !== null && amount !== undefined && currency) {
    return `${currency} ${amount.toLocaleString('en-US', { minimumFractionDigits: 2 })}`
  }
  return ''
}

export const PLATFORM_TYPE_SALESFORCE = 'Skedulo on Salesforce'
export const PLATFORM_TYPE_SKEDULO = 'Skedulo Platform'

const CustomerSalesforceDataForm = ({
  data,
  loading,
  salesforceId
}: {
  data?: ISalesforceAccountWithContracts
  loading: boolean
  salesforceId: string
}) => {
  const sfAccountText = "customer's account in the Skedulo Salesforce org."
  const sfAccountUrl = `https://sked.lightning.force.com/lightning/r/Account/${salesforceId}/view`

  const form = {
    title: 'Account data',
    description: (
      <>
        This information is retrieved from the{' '}
        <a href={sfAccountUrl} target="_blank" rel="noreferrer">
          {sfAccountText}
        </a>
      </>
    )
  }
  const account = data?.result
  const notFoundError = `Account data with Salesforce ID: ${salesforceId} is not found.`
  const activeAcv = `${formatCurrency(account?.ActiveACV, account?.CurrencyIsoCode)}`
  const activeACVUSD = `${formatCurrency(account?.ActiveACVUSD, 'USD')}`

  return (
    <LoaderWrap loading={loading}>
      {account ? (
        <Grid columns="1" style={{ marginTop: '2px' }}>
          <Grid.Column textAlign="left" verticalAlign="middle" style={{ marginBottom: '2px' }}>
            <Header as="h3">{form.title}</Header>
            <p>{form.description}</p>
          </Grid.Column>
          <Grid columns="2" style={{ marginBottom: '2px' }}>
            {renderRow('Account Name', <>{account?.Name}</>)}
            {account.Type &&
              renderRow(
                'Type',
                account.Type ? (
                  <Label color={mapColorToAccountStatus(account.Type)} style={{ marginLeft: '0px' }}>
                    {account?.Type}
                  </Label>
                ) : (
                  <></>
                )
              )}
            {renderRow('Account Owner', <>{account?.Owner?.Name}</>)}
            {renderRow('CSM', <>{account?.CSM?.Name}</>)}
            {renderRow('Active ACV', <>{activeAcv}</>)}
            {renderRow('Active ACV (USD)', <>{activeACVUSD}</>)}
            {renderRow('Customer Start Date', <>{formatDate(account?.CustomerStartDate)}</>)}
            {renderRow('Primary Use Case', <>{account?.PrimaryUseCase}</>)}
            {renderRow('Industry', <>{account?.Industry}</>)}
            {renderRow('Support Tier', <>{account?.SupportTier}</>)}
            {renderRow(
              'Customer Happiness',
              account.CustomerHappiness ? (
                <Label color={mapColorToSemanticColor(account.CustomerHappiness)} style={{ marginLeft: '0px' }}>
                  {account?.CustomerHappiness}
                </Label>
              ) : (
                <></>
              )
            )}
            {renderRow(
              'Customer Happiness Prior Value',
              account.CustomerHappinessPriorValue ? (
                <Label
                  color={mapColorToSemanticColor(account.CustomerHappinessPriorValue)}
                  style={{ marginLeft: '0px' }}
                >
                  {account?.CustomerHappinessPriorValue}
                </Label>
              ) : (
                <></>
              )
            )}
            {renderRow('Customer Stage', <>{account?.CustomerStage}</>)}
            {renderRow('Customer Contracted End Date', <>{formatDate(account?.CurrentContractEndDate)}</>)}
            {renderRow('Customer Go Live Date', <>{formatDate(account?.CustomerGoLiveDate)}</>)}
            {renderRow(
              'Platform Type',
              <>{account?.PlatformType === PLATFORM_TYPE_SKEDULO ? 'Skedulo Pulse Platform' : account?.PlatformType}</>
            )}
            {renderRow('External Products', <>{renderItemStringWithBullet(account.ExternalProducts)}</>)}
            {renderRow('Skedulo Add Ons', <>{renderItemStringWithBullet(account.EnriteProduct)}</>)}
            {account?.PlatformType === PLATFORM_TYPE_SALESFORCE &&
              renderRow('Total Licensed Seats', <>{account?.TotalLicensedSeats}</>)}
            {account?.PlatformType === PLATFORM_TYPE_SALESFORCE &&
              renderRow('Total Used Licenses', <>{account?.TotalUsedLicences}</>)}
            {renderRow(
              'Customer Happiness Notes',
              <>{formatCustomerHappinessNotes(account?.CustomerHappinessNotes)}</>
            )}
          </Grid>
        </Grid>
      ) : (
        <Message error content={notFoundError} />
      )}
    </LoaderWrap>
  )
}

export default CustomerSalesforceDataForm
