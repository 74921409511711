import { Segment, Grid, Input, Pagination, Label } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { useGetPackageTenantCountQuery } from '../../../queries/packages/GetPackageTenantCountQuery'
import { Table } from '../../../components/TableComponents/ReactTable'
import { IPackageVersionWithTenantCount } from '../../../actions/Package'
import { handlePaginationChange } from '../../../views/tableComponents/TableFunctions'
import { formatDate } from '../../../utils/dateUtils'
import { IRegionInfo } from '../../../actions/Regions'
import { combinePackageVersionsArrays, generatePackageVersionTenantQueries } from '../services/PackageTenantCountUtils'
import {
  PackageVersionQueryResponse,
  useGetPackageVersionQuery
} from '../../../queries/packages/GetPackageVersionQuery'
import { useInfiniteTableService } from '../services/InfiniteTableService'
import { TotalTeamsCell } from '../GlobalPackageList'

export interface IPackageVersionsList {
  packageName: string
  title: string
  regionsInfo: IRegionInfo[]
  latestVersion: string | undefined
}

export const getPackageVersionLink = (packageName: string, version: string, latestVersion: string | undefined) => (
  <a href={`/packages/${encodeURIComponent(packageName)}/${encodeURIComponent(version)}`}>
    {version}
    {version === latestVersion && latestVersion ? (
      <Label size="mini" style={{ marginLeft: '5px' }}>
        Latest
      </Label>
    ) : (
      ''
    )}
  </a>
)

export const PackageVersionsList = (props: IPackageVersionsList) => {
  const { title, packageName, regionsInfo, latestVersion } = props

  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [activePage, setActivePage] = useState(1)
  const packagesVersionInfiniteQueryResult = useGetPackageVersionQuery(packageName, debouncedSearchValue)

  const {
    pageData,
    isLoading: isLoadingPackageVersion,
    maxPages,
    handleSearchChange
  } = useInfiniteTableService<PackageVersionQueryResponse>({
    infiniteQueryResult: packagesVersionInfiniteQueryResult,
    setSearchValue,
    activePage,
    setActivePage
  })
  const packageVersions = pageData?.result

  const { data: packageTenantCounts, isLoading: isLoadingPackageVersionTenantCount } = useGetPackageTenantCountQuery(
    regionsInfo,
    generatePackageVersionTenantQueries(packageVersions || [])
  )
  const packageVersionsWithTenantCount = combinePackageVersionsArrays(packageVersions || [], packageTenantCounts || [])

  const packageVersionColumns = [
    {
      Header: 'Version',
      accessor: (packageVersionWithTenantCount: IPackageVersionWithTenantCount) =>
        packageVersionWithTenantCount.version,
      Cell: ({ row }: { row: Row<IPackageVersionWithTenantCount> }) =>
        getPackageVersionLink(packageName, row.original.version, latestVersion)
    },
    {
      Header: 'Total teams',
      accessor: (packageVersionWithTenantCount: IPackageVersionWithTenantCount) => packageVersionWithTenantCount.count,
      Cell: ({ row }: { row: Row<IPackageVersionWithTenantCount> }) => <TotalTeamsCell count={row.original.count} />
    },
    {
      Header: 'Description',
      accessor: (packageVersionWithTenantCount: IPackageVersionWithTenantCount) =>
        packageVersionWithTenantCount.description
    },
    {
      Header: 'Created',
      accessor: (packageVersionWithTenantCount: IPackageVersionWithTenantCount) =>
        formatDate(packageVersionWithTenantCount.createdAt, 'MMM DD, YYYY - hh:mma')
    },
    {
      Header: 'Created by',
      accessor: (packageVersionWithTenantCount: IPackageVersionWithTenantCount) =>
        packageVersionWithTenantCount.createdBy
    }
  ]

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9} verticalAlign="middle">
            <h3>{title}</h3>
          </Grid.Column>
          <Grid.Column width={7} textAlign="right">
            <Input
              placeholder="Search"
              onChange={handleSearchChange}
              icon="search"
              iconPosition="left"
              value={searchValue}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table
        columns={packageVersionColumns}
        data={packageVersionsWithTenantCount ?? []}
        emptyMessage="No package versions found"
        textAlign="left"
        loading={isLoadingPackageVersion || isLoadingPackageVersionTenantCount}
      />

      {maxPages > 1 && (
        <Pagination
          activePage={activePage}
          boundaryRange={0}
          siblingRange={4}
          onPageChange={handlePaginationChange(setActivePage)}
          totalPages={maxPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
        />
      )}
    </Segment>
  )
}
