import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { checkDeploymentValidity, DeploymentValidationResult } from '../../actions/Package'

export const useCheckDeploymentValidity = (
  packageName: string,
  packageVersion: string,
  tenantId: string,
  region: string,
  isTenantInstall: boolean
): UseQueryResult<DeploymentValidationResult> =>
  useQuery(['packageVersion', packageName, packageVersion, tenantId, region, isTenantInstall], async () => {
    if (!packageName) {
      return {}
    }
    return checkDeploymentValidity(packageName, packageVersion, tenantId, region).catch((error: AxiosError<any>) => {
      // Validate correct API and package(+version) exists for internal install
      // Validate only package(+version) exists for tenant install, API check happens on install
      if (!isTenantInstall || error.response?.status === 404) {
        return {
          message: error.response?.data.message
        }
      }
      return {}
    })
  })
