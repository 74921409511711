import { Grid, GridColumn, Header, Icon } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useEffect, useMemo, useState } from 'react'
import { groupBy, isNumber, reverse, sortBy } from 'lodash'
import { useDebounce } from 'usehooks-ts'
import { Link, useSearchParams } from 'react-router-dom'
import {
  IPackage,
  IPackageProperties,
  IPackageWithTenantCount,
  PACKAGE_OWNER_TYPES,
  PACKAGE_STATUSES,
  PACKAGE_TYPES
} from '../../actions/Package'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { formatDate } from '../../utils/dateUtils'
import { Table, createMemoizedColumns } from '../../components/TableComponents/ReactTable'
import { useGetPackageTenantCountQuery } from '../../queries/packages/GetPackageTenantCountQuery'
import { useGetPackagesInfiniteQuery } from '../../queries/GetPackagesInfiniteQuery'
import { FilterCategoryOptions, Filters, filtersMatchAny, searchParamsToFilters } from '../filterComponents/Filters'
import { SortableHeaderCell } from '../../components/TableComponents/BaseTableComponents/SortableHeaderCell'
import { sortData } from '../tableComponents/TableFunctions'
import { FilterSearch } from '../filterComponents/FilterSearch'
import { combinePackageArrays, generatePackageTenantQueries } from './services/PackageTenantCountUtils'
import { OwnerTypeComponent, StatusComponent, TypeComponent } from './services/PackageInformationFields'

const PackageNameURL = ({ packageName }: { packageName: string }) => (
  <Link to={`/packages/${encodeURIComponent(packageName)}`} data-testid="package-name-cell">
    {packageName}
  </Link>
)

const LatestVersionURL = ({ packageName, packageVersion }: { packageName: string; packageVersion: string }) => (
  <Link to={`/packages/${encodeURIComponent(packageName)}/${encodeURIComponent(packageVersion)}`}>
    {packageVersion}{' '}
  </Link>
)

export const TotalTeamsCell = ({ count }: { count: number | null }) =>
  isNumber(count) ? <>{count}</> : <Icon name="sync" color="grey" loading={true} />

const GlobalPackageList = () => {
  const [searchParams] = useSearchParams()
  const [{ column: sortColumn, direction: sortDirection }, setSort] = useState<{
    column: string
    direction: 'ascending' | 'descending'
  }>({
    column: 'packageName',
    direction: 'ascending'
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const { data: regionsInfo, isLoading: isLoadingRegions } = useGetRegionQuery()
  const {
    isLoading: isLoadingPackages,
    fetchNextPage,
    hasNextPage,
    data
  } = useGetPackagesInfiniteQuery(debouncedSearchValue)
  const [allPackages, setAllPackages] = useState<IPackage[]>(data?.pages[0].result ?? [])
  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
    setAllPackages(data?.pages.flatMap(p => p.result) ?? [])
  }, [data, fetchNextPage, hasNextPage])

  const { data: packageTenantCounts, isLoading: isLoadingCounts } = useGetPackageTenantCountQuery(
    regionsInfo || [],
    generatePackageTenantQueries(allPackages || [])
  )
  const packagesWithTenantCount = combinePackageArrays(allPackages || [], packageTenantCounts || [])

  const filterCategories = useMemo(
    () =>
      new Map<string, FilterCategoryOptions>([
        ['keyword', { text: 'Keyword', hideExactMatch: true }],
        ['packageName', { text: 'Name' }],
        ['latestVersionDescription', { text: 'Latest version description' }],
        ['ownerType', { text: 'Owner', values: PACKAGE_OWNER_TYPES }],
        ['status', { text: 'Status', values: PACKAGE_STATUSES }],
        ['type', { text: 'Type', values: PACKAGE_TYPES }]
      ]),
    []
  )

  const handleSort = (column: string) => () => {
    const flipDirection = sortDirection === 'ascending' ? 'descending' : 'ascending'
    const direction = sortColumn === column ? flipDirection : 'ascending'
    setSort({ column, direction })
  }

  const filterPackages = (): IPackageWithTenantCount[] => {
    if (!packagesWithTenantCount || packagesWithTenantCount.length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = packagesWithTenantCount
    const groups = groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      if (category !== 'keyword') {
        result = result.filter(p =>
          filtersMatchAny(
            categoryFilters,
            (p[category as keyof IPackageWithTenantCount] as string) ??
              (p.properties[category as keyof IPackageProperties] as string),
            false
          )
        )
      }
    }
    return result
  }

  useEffect(() => {
    const filters = searchParamsToFilters(searchParams, filterCategories)
    const keyword = filters.filter(f => f.category === 'keyword')?.[0]?.value ?? ''
    setSearchValue(keyword)
  }, [filterCategories, searchParams])

  const SortablePackageHeaderCell = SortableHeaderCell(sortColumn, sortDirection, handleSort)

  const sortPackages = () => {
    if (['status', 'type'].includes(sortColumn)) {
      if (sortDirection === 'ascending') {
        return sortBy(filterPackages(), p => p.properties[sortColumn as keyof IPackageProperties])
      }
      return reverse(sortBy(filterPackages(), p => p.properties[sortColumn as keyof IPackageProperties]))
    } else {
      return sortData(filterPackages(), sortColumn, sortDirection)
    }
  }

  const columns = createMemoizedColumns<IPackageWithTenantCount>(
    [
      {
        Header: <SortablePackageHeaderCell title="Name" name="packageName" />,
        accessor: 'packageName',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <PackageNameURL packageName={row.original.packageName} />
        )
      },
      {
        Header: 'Latest version',
        accessor: 'latestVersion',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <LatestVersionURL packageName={row.original.packageName} packageVersion={row.original.latestVersion} />
        )
      },
      {
        Header: 'Latest version description',
        accessor: 'latestVersionDescription',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <div className="table-cell-ellipsis">{row.original.latestVersionDescription}</div>
        )
      },
      {
        Header: <SortablePackageHeaderCell title="Total teams" name="count" />,
        accessor: 'count',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => <TotalTeamsCell count={row.original.count} />
      },
      {
        Header: <SortablePackageHeaderCell title="Created" name="createdAt" />,
        accessor: 'createdAt',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <>{formatDate(row.original.createdAt, 'MMM DD, YYYY - hh:mma')}</>
        )
      },
      {
        Header: <SortablePackageHeaderCell title="Latest version created" name="latestCreatedAt" />,
        accessor: 'latestCreatedAt',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <>{formatDate(row.original.latestCreatedAt, 'MMM DD, YYYY - hh:mma')}</>
        )
      },
      {
        Header: <SortablePackageHeaderCell title="Owner" name="ownerType" />,
        accessor: 'ownerType',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => <OwnerTypeComponent value={row.original.ownerType} />
      },
      {
        Header: <SortablePackageHeaderCell title="Status" name="status" />,
        accessor: (pkg: IPackageWithTenantCount) => pkg.properties.status,
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <StatusComponent value={row.original.properties.status} />
        ),
        id: 'status'
      },
      {
        Header: <SortablePackageHeaderCell title="Type" name="type" />,
        accessor: (pkg: IPackageWithTenantCount) => pkg.properties.type,
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <TypeComponent value={row.original.properties.type} />
        ),
        id: 'type'
      }
    ],
    [sortColumn, sortDirection]
  )

  return (
    <div className="route-component packages">
      <Header as="h2">Packages</Header>
      <Grid>
        <Grid.Row>
          <GridColumn width={3}>
            <FilterSearch
              category="keyword"
              inputProps={{ icon: 'search', iconPosition: 'left' }}
              placeholder="Search name or description"
            />
          </GridColumn>
          <GridColumn width={13}>
            <Filters filterCategories={filterCategories} />
          </GridColumn>
        </Grid.Row>
      </Grid>
      <div className="scrollable">
        <Table
          columns={columns}
          data={sortPackages() ?? []}
          emptyMessage="No packages found."
          textAlign="left"
          color="blue"
          loading={isLoadingCounts || isLoadingPackages || isLoadingRegions}
          className="sticky-table"
        />
      </div>
    </div>
  )
}

export default GlobalPackageList
