import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast, createInfoToast, createSuccessToast } from '../../views/alertComponents/Alert'
import { getPackageByName, installPackage, PackageInstallationRequest } from '../../actions/Package'

export const useInstallPackageMutation = (successFn: () => void) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (props: { request: PackageInstallationRequest; region: string; token?: string }) => {
      const { packageName, version, tenantIds } = props.request

      if (!version) {
        // get package latest version when the version is unavailable
        const packageData = await getPackageByName(packageName)
        const latestVersion = packageData?.latestVersion

        createInfoToast(
          `Installing "${packageName}" using latest version "${latestVersion}" for tenantId: \n${tenantIds.toString()}`
        )
        return installPackage(
          {
            packageName,
            version: latestVersion,
            tenantIds
          },
          props.region,
          props.token
        )
      }

      createInfoToast(`Installing "${packageName}" using version "${version}" for tenantId: \n${tenantIds}`)
      return installPackage(
        {
          packageName,
          version,
          tenantIds
        },
        props.region,
        props.token
      )
    },
    onSuccess: async (data, props) => {
      await Promise.all([
        queryClient.invalidateQueries(['InstalledPackage']),
        queryClient.invalidateQueries(['package', props.request.packageName]),
        queryClient.invalidateQueries(['packageVersion', props.request.packageName]),
        queryClient.invalidateQueries(['packageTenants', props.request.packageName, props.request.version])
      ])

      createSuccessToast('Package installation started successfully.')
      successFn()
    },
    onError: (error: Error) => createErrorToast(error)
  })
}
