import { useSearchParams } from 'react-router-dom'
import { Input, InputProps } from 'semantic-ui-react'

export const FilterSearch = (props: { category: string; placeholder?: string; inputProps?: any }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onChange = (_event: React.SyntheticEvent, data: InputProps) => {
    const value = (data.value ?? '').replaceAll('~', '')
    setSearchParams(
      prevSearchParams => {
        if (value === '') {
          prevSearchParams.delete(props.category)
        } else {
          prevSearchParams.set(props.category, `~${value}`)
        }
        return prevSearchParams
      },
      { replace: true }
    )
  }

  return (
    <Input
      name="filterSearch"
      value={(searchParams.get(props.category) ?? '').replace('~', '')}
      onChange={onChange}
      placeholder={props.placeholder ?? 'Search'}
      fluid
      {...props.inputProps}
    />
  )
}
