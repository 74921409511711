import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { countAllPackageTenants, IPackageTenantCount } from '../../actions/Package'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { IRegionInfo } from '../../actions/Regions'
import { PackageTenantQuery } from '../../views/package/services/PackageTenantCountUtils'

export const useGetPackageTenantCountQuery = (
  regions: IRegionInfo[],
  tenantQueries: PackageTenantQuery[]
): UseQueryResult<IPackageTenantCount[]> =>
  useQuery(
    ['packageTenantCount', tenantQueries],
    () => (tenantQueries ? countAllPackageTenants(regions, tenantQueries) : []),
    {
      onError: error => createErrorToast(error)
    }
  )
