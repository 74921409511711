import { DatadogLogsButton } from '../../../components/DatadogButtons'

export const DatadogLambdaLogsButton = (props: {
  lambda: string
  startTime?: number
  endTime?: number
  title?: string
}) => (
  <DatadogLogsButton
    startTime={props.startTime}
    endTime={props.endTime}
    url={`https://app.datadoghq.com/logs?query=functionname%3A${props.lambda.toLowerCase()}`}
    altText={`Datadog logs for ${props.lambda}`}
    title={props.title}
  />
)

export const DatadogApplicationLogsButton = (props: {
  application: string
  env: string
  instance: string
  startTime?: number
  endTime?: number
  title?: string
}) => (
  <DatadogLogsButton
    startTime={props.startTime}
    endTime={props.endTime}
    url={`https://app.datadoghq.com/logs?query=service%3A${props.application}%20env%3A${props.instance}-${props.env}`}
    altText={`Datadog logs for ${props.application} in ${props.instance}-${props.env}`}
    title={props.title}
  />
)
