import { Users } from '../../../views/tenants/components/Users/Users'
import { useTenantApiService } from '../services/TenantApiService'

const TeamUsers = ({ teamId, metadata }: { teamId: string; customerId: string; metadata: Record<string, any> }) => {
  const tenantApiService = useTenantApiService({
    teamId
  })
  return (
    <Users
      tenantId={tenantApiService.tenantId}
      teamApi={tenantApiService.teamAPI}
      vendor={tenantApiService.vendor}
      metadata={metadata}
      teamId={teamId}
    />
  )
}

export default TeamUsers
