import { Checkbox, Button, Radio } from 'semantic-ui-react'
import { isUndefined } from 'lodash'

import { Customer } from '../../../actions/NewCustomers'
import { validateSalesforceOrgId } from '../../../actions/Tenants'
import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { useUpdateCustomerMutation } from '../../../mutations/UpdateCustomerMutation'
import { useEditableRecordInformationService } from '../../../services/EditableRecordInformationService'
import { RegionFlag, RegionFlagPopup } from '../../../utils/regionFlags'

export const useCustomerInformationService = (customerId: string) => {
  const getCustomerQuery = useGetCustomerQuery(customerId)
  const { data: regionsInfo } = useGetRegionQuery()
  const updateCustomerMutation = useUpdateCustomerMutation(customerId)
  return useEditableRecordInformationService<Customer>('customer', getCustomerQuery, updateCustomerMutation, [
    {
      fieldName: 'id',
      headerTitle: 'ID',
      isString: true,
      editable: false
    },
    {
      fieldName: 'name',
      isString: true,
      editable: true
    },
    {
      fieldName: 'salesforceId',
      headerTitle: 'Salesforce Account ID',
      isString: true,
      editable: true,
      showField: (values, editedValues) => editing => (editing ? !editedValues?.isInternal : !values?.isInternal),
      validateField: editedData => {
        if (!isUndefined(editedData?.isInternal) && !editedData?.isInternal) {
          return validateSalesforceOrgId(editedData?.salesforceId)
        }
        return true
      },
      DisplayComponent: ({ value }) =>
        value ? (
          <a
            href={`https://sked.lightning.force.com/lightning/r/Account/${value}/view`}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        ) : (
          <p>None</p>
        )
    },
    {
      fieldName: 'region',
      headerTitle: 'Region',
      isString: false,
      editable: true,
      DisplayComponent: ({ value }) =>
        value ? <RegionFlagPopup region={value} /> : <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>,
      validateField: editedData => {
        if (!isUndefined(editedData?.isInternal) && !editedData?.isInternal) {
          return validateSalesforceOrgId(editedData?.salesforceId)
        }
        return true
      },
      EditComponent: ({ editedValue, editFn }) => (
        <>
          {regionsInfo?.map(region => (
            <Button
              key={region.region}
              className={editedValue === region.region.toUpperCase() ? 'radio selected' : 'radio'}
              data-testid={`radio-${region.region}`}
              onClick={(_, data) => editFn((data.selectedRegion || region.region.toUpperCase()) as string)}
              basic
              fluid
            >
              <Radio
                label={
                  <label>
                    {region.name}&nbsp;&nbsp;
                    <RegionFlag region={region.region} />
                  </label>
                }
                name="regionCheckboxGroup"
                value={region.region}
                checked={editedValue === region.region.toUpperCase()}
              />
            </Button>
          ))}
        </>
      )
    },
    {
      fieldName: 'isInternal',
      headerTitle: 'Is Internal',
      isString: false,
      editable: true,
      DisplayComponent: ({ value }) => <Checkbox disabled checked={value} />,
      EditComponent: ({ editedValue, editFn }) => (
        <Checkbox checked={editedValue as boolean} onClick={() => editFn(!editedValue)} />
      )
    },
    {
      fieldName: 'createdBy',
      isString: true,
      editable: false
    },
    {
      fieldName: 'updatedBy',
      isString: true,
      editable: false
    },
    {
      fieldName: 'createdAt',
      isString: true,
      editable: false
    },
    {
      fieldName: 'updatedAt',
      isString: true,
      editable: false
    }
  ])
}
