import { Table } from '../../components/TableComponents/ReactTable'
import { sortData } from '../tableComponents/TableFunctions'
import { TeamWithFeatures } from './NewFeatureFlagReport'
import { getTeamsColumns } from './component/NewTeamsList'

interface displayTeamProps {
  teams: TeamWithFeatures[]
  featureFlag: string
  checkEnabled: boolean
  showCustomerOnly: boolean
  showProductionOnly: boolean
}

export const displayTeams = (props: displayTeamProps) =>
  sortData(
    props.teams
      .filter(team => !props.showCustomerOnly || team.owner === 'Customer')
      .filter(team => !props.showProductionOnly || team.environment === 'Production')
      .filter(team => {
        const enabled = team.featureFlags.find(flag => flag.name === props.featureFlag)?.value
        return (enabled && props.checkEnabled) || (!enabled && !props.checkEnabled)
      }),
    'primaryName',
    'ascending'
  )

export const FeatureEnabledForTeamList = (props: { data: TeamWithFeatures[]; loading: boolean }) => {
  const columns = getTeamsColumns(true, true)
  return (
    <>
      {props.data.length > 0 && <small>{props.data.length} teams</small>}
      <div className="scrollable-with-pagination" style={{ maxHeight: '70%' }}>
        <Table
          loading={props.loading}
          columns={columns}
          data={props.data}
          emptyMessage={'Click the Fetch data button to load the latest feature flag data'}
          celled
          color="blue"
          textAlign="left"
          className="sticky-table"
        />
      </div>
    </>
  )
}
