import { Button, Checkbox, Grid, Header } from 'semantic-ui-react'
import { CSVLink } from 'react-csv'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { capitalize } from 'lodash'
import { IUserList } from '../../../../actions/Tenants'
import { VendorClaim } from '../../../../actions/Utils'
import { UsersSearchFilterValues, useSearchFilterStore } from '../../../../context/SearchFilterStoreContext'
import { useFilterService } from '../../../../services/DynamoQueryFilterService'
import DynamoQuerySearchControls from '../../../../components/SearchComponents/DynamoQuerySearchControls'
import { filterData } from '../../../tableComponents/TableFunctions'
import { formatDate } from '../../../../utils/dateUtils'
import { useUserSummaryService, useUserListService, salesforceWarning } from './UsersService'
import { UserList } from './UserList'
import { UserCounts } from './UserCounts'

export interface IUserListFormatted {
  UserID: string
  CreatedDate: string
  CreatedDateISO: string
  UserActive: string
  Email: string
  Roles: string | null
  LastLoginDate?: string | null
  LastLoginDateISO?: string | null
  Status?: string | null
}

const DATE_TIME_FORMAT = 'MMM D YYYY, h:mm:ssa'

export const getFormattedUserList = (data: IUserList[] | undefined, isSalesforce: boolean) =>
  data?.map(user => {
    const formattedUserWithoutStatus = {
      UserID: user.userId,
      CreatedDate: formatDate(user.createdAt, DATE_TIME_FORMAT),
      CreatedDateISO: user.createdAt,
      UserActive: capitalize(user.active.toString()),
      Email: user.maskedEmail,
      Roles: user.roleNames ? user.roleNames.join(', ') : ''
    }
    return isSalesforce
      ? formattedUserWithoutStatus
      : {
          ...formattedUserWithoutStatus,
          LastLoginDate: user.lastLoginDate ? formatDate(user.lastLoginDate, DATE_TIME_FORMAT) : null,
          LastLoginDateISO: user.lastLoginDate,
          Status: user.status
        }
  }) ?? []

const searchableParameters = ['all', 'UserID', 'Email', 'Roles']

export const Users = ({
  tenantId,
  teamApi,
  vendor,
  metadata,
  teamId
}: {
  tenantId?: string
  teamApi?: string
  vendor?: VendorClaim
  metadata?: Record<string, any>
  teamId?: string
}) => {
  // Search filters
  const { usersSearchFilter, setUsersSearchFilter } = useSearchFilterStore()
  const { searchValue, searchOptions, handleSearchCategoryChange, handleSearchChange } = useFilterService(
    searchableParameters,
    'all'
  )
  const debouncedSearchValues = useDebounce(searchValue, 500)

  // Checkbox filters
  const onToggleFilter = (filter: keyof UsersSearchFilterValues) => () => {
    setUsersSearchFilter({
      ...usersSearchFilter,
      [filter]: !usersSearchFilter[filter]
    })
  }

  // Check search filter store on mount and set search value and category
  useEffect(() => {
    if (usersSearchFilter.searchValue) {
      handleSearchChange({}, { value: usersSearchFilter.searchValue })
    }
    if (usersSearchFilter.searchCategory) {
      handleSearchCategoryChange({}, { value: usersSearchFilter.searchCategory })
    }
  }, [])

  // Update search filter store for users
  useEffect(() => {
    const value = debouncedSearchValues.searchValue
    const category = debouncedSearchValues.searchCategory
    setUsersSearchFilter({ ...usersSearchFilter, searchValue: value, searchCategory: category })
  }, [debouncedSearchValues])

  // Data gathering
  const userListService = useUserListService(
    tenantId,
    teamApi,
    usersSearchFilter.includeNonActiveUsers,
    usersSearchFilter.includeUsersWithoutRoles
  )
  const userSummaryService = useUserSummaryService(tenantId ? [tenantId] : undefined, teamApi)

  // Data Manipulation
  const [formattedUserList, setFormattedUserList] = useState<IUserListFormatted[]>([])
  useEffect(
    () => setFormattedUserList(getFormattedUserList(userListService.data, vendor === VendorClaim.Salesforce)),
    [userListService.data]
  )

  const [filteredUsers, setFilteredUsers] = useState<IUserListFormatted[]>([])
  useEffect(
    () => setFilteredUsers(filterData(searchValue, formattedUserList || [])),
    [formattedUserList, debouncedSearchValues]
  )

  return (
    <div data-testid="users">
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <Header as="h3">Users</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <CSVLink data={filteredUsers} filename={'user_list_' + tenantId}>
            <Button className={'form-button-sked-blue'} content={'Export to CSV'} />
          </CSVLink>
        </Grid.Column>
      </Grid>
      {salesforceWarning(false, vendor)}
      {userSummaryService.data && userSummaryService.data.length > 0 && (
        <UserCounts
          data={userSummaryService.data}
          loading={userSummaryService.isFetching}
          hideLimitForTeam={vendor === VendorClaim.Salesforce || !teamId}
          metadata={metadata}
          teamId={teamId}
        />
      )}
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <DynamoQuerySearchControls
            isFetching={userListService.isLoading}
            refetch={userListService.refetch}
            searchValue={searchValue}
            searchOptions={searchOptions}
            handleSearchChange={handleSearchChange}
            handleSearchCategoryChange={handleSearchCategoryChange}
            excludeSpinner
          />
        </Grid.Column>
        <Grid.Column>
          <Checkbox
            label="Include non-active users"
            toggle
            checked={!usersSearchFilter.includeNonActiveUsers}
            onClick={onToggleFilter('includeNonActiveUsers')}
            style={{ padding: '4px' }}
          />

          <Checkbox
            label="Include users without a role"
            toggle
            checked={!usersSearchFilter.includeUsersWithoutRoles}
            onClick={onToggleFilter('includeUsersWithoutRoles')}
            style={{ padding: '4px' }}
          />
        </Grid.Column>
      </Grid>
      <UserList
        data={filteredUsers}
        loading={userListService.isFetching}
        isSalesforce={vendor === VendorClaim.Salesforce}
      />
    </div>
  )
}
