import { Table, Checkbox } from 'semantic-ui-react'
import { IMobileV2Form } from '../../../../actions/MobileV2Forms'
import { MobileExtensionsStatus } from './mobileExtensionsConstants'
import { MobileFormStatus } from './MobileFormStatus'

enum MobileV2FormType {
  Job = 'Job',
  Resource = 'Resource'
}

export const getFormJobTypeList = (form: IMobileV2Form) => form.jobTypes ?? []

export const getFormStatus = (form: IMobileV2Form) => {
  const hasJobTypes = getFormJobTypeList(form)?.length > 0
  return hasJobTypes ? MobileExtensionsStatus.Published : MobileExtensionsStatus.Draft
}

export const getFormType = (form: IMobileV2Form) => {
  const formDefinition = form.formRev?.definition
  const deployContext = formDefinition?.deploy?.context
  return deployContext === 'resource' ? MobileV2FormType.Resource : MobileV2FormType.Job
}

const getJobTypeList = (form: IMobileV2Form) => getFormJobTypeList(form)?.join(', ')

export const getFormRevId = (form: IMobileV2Form) => form.formRev?.id ?? '-'

export const getChildFormNameList = (form: IMobileV2Form) =>
  form.formRev?.definition?.forms.map(item => item.name) ?? []

export const getLocalShowInV3 = (form: IMobileV2Form, localShowInV3Map: Map<string, boolean>) =>
  localShowInV3Map.get(form.id) ?? false

interface Props {
  form: IMobileV2Form
  editing: boolean
  localShowInV3Map: Map<string, boolean>
  handleShowInV3Change(form: IMobileV2Form, value: boolean): void
}

export const MobileV2FormRow: React.FC<Props> = ({ form, editing, localShowInV3Map, handleShowInV3Change }) => {
  const childFormNameList = getChildFormNameList(form)
  const formRevId = getFormRevId(form)
  const formType = getFormType(form)
  const jobTypeList = getJobTypeList(form)
  const formStatus = getFormStatus(form)
  const showInV3 = getLocalShowInV3(form, localShowInV3Map)

  return (
    <Table.Row data-testid={form.id} key={form.id} className="form-item">
      <Table.Cell>
        {
          <div>
            {childFormNameList.map(formName => (
              <p key={form.id} style={{ marginBottom: '0.5rem' }}>
                {formName}
              </p>
            ))}
          </div>
        }
      </Table.Cell>
      <Table.Cell>{formRevId}</Table.Cell>
      <Table.Cell>{formType}</Table.Cell>
      <Table.Cell>{jobTypeList}</Table.Cell>
      <Table.Cell>
        <Checkbox
          data-testid={`show-in-v3-${form.id}`}
          toggle
          checked={showInV3}
          onChange={(_, data) => {
            handleShowInV3Change(form, data.checked ?? false)
          }}
          disabled={!editing}
        />
      </Table.Cell>
      <Table.Cell>
        <MobileFormStatus status={formStatus} />
      </Table.Cell>
    </Table.Row>
  )
}
