import { Button, Popup } from 'semantic-ui-react'

const DatadogIcon = (props: { altText: string }) => (
  <Button className="icon datadog">
    <i className="icon datadog">
      <img width={26} height={26} src={require('../images/dd_icon_white.svg')} alt={props.altText} />
    </i>
  </Button>
)

export const DatadogButton = (props: { altText: string; url: string; title?: string }) => (
  <Popup
    content={props.altText}
    trigger={
      <a href={props.url} target="_blank" rel="noreferrer">
        <DatadogIcon altText={props.altText} />
        {props.title ? ` ${props.title}` : ''}
      </a>
    }
  />
)

export const DatadogLogsButton = (props: {
  url: string
  altText: string
  startTime?: number
  endTime?: number
  title?: string
}) => {
  let url = `${props.url}&live=false`
  if (props.startTime) {
    url = url + `&from_ts=${props.startTime}`
  }
  if (props.endTime) {
    url = url + `&to_ts=${props.endTime}`
  }
  return <DatadogButton altText={props.altText} url={url} title={props.title} />
}

export const DatadogAPMButton = (props: {
  application: string
  env: string
  instance: string
  startTime: number
  endTime: number
  title?: string
}) => {
  const from = props.startTime
  const to = props.endTime
  const ddenv = `${props.instance}-${props.env}`
  const altText = `Datadog service dashboard for ${props.application} in ${ddenv}`
  const url = `https://app.datadoghq.com/apm/services/${props.application}?env=${ddenv}&isInferred=false&start=${from}&end=${to}`

  return <DatadogButton altText={altText} url={url} title={props.title} />
}
