import { Header, Segment } from 'semantic-ui-react'
import { DisplayOnlyRecordInformationService } from '../../services/EditableRecordInformationService'
import { LoaderWrap } from '../../views/loadingComponents/LoaderWrap'
import { InformationComponent, renderEditableDisplay } from './InformationComponent'

export const DisplayOnlyInformation = <T extends object>({
  title,
  loading,
  editing,
  fields
}: DisplayOnlyRecordInformationService<T>) => {
  const display = renderEditableDisplay(fields, editing, undefined, true)
  const header = <Header>{title}</Header>

  return (
    <Segment>
      <LoaderWrap loading={loading}>
        <InformationComponent useAlternateGridUI header={header} display={display} />
      </LoaderWrap>
    </Segment>
  )
}
