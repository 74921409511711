import { Button, Header, Segment } from 'semantic-ui-react'
import { LoaderWrap } from '../../views/loadingComponents/LoaderWrap'
import { EditableRecordInformationService } from '../../services/EditableRecordInformationService'
import { InformationComponent, renderEditableDisplay } from './InformationComponent'

export const renderEditButton = <T extends object>(
  editing: boolean,
  submitting: boolean,
  saveEdits: (editedData: Partial<T>) => void,
  editedData: Partial<T>,
  editIsValid: boolean,
  toggleEditing: () => void
) => (
  <>
    {editing && (
      <Button
        primary
        onClick={() => saveEdits(editedData)}
        floated="right"
        disabled={!editIsValid}
        loading={submitting}
        content="Save"
      />
    )}
    <Button onClick={toggleEditing} content={editing ? 'Cancel' : 'Edit'} />
  </>
)

export const EditableInformation = <T extends object>({
  title,
  loading,
  editing,
  submitting,
  fields,
  saveEdits,
  editedData,
  editIsValid,
  toggleEditing,
  useAlternateGridUI
}: EditableRecordInformationService<T>) => {
  const editButton = renderEditButton(editing, submitting, saveEdits, editedData, editIsValid, toggleEditing)
  const display = renderEditableDisplay(fields, editing, undefined, useAlternateGridUI)
  const header = <Header>{title}</Header>

  return (
    <div>
      {useAlternateGridUI ?? header}
      <Segment color={useAlternateGridUI ? undefined : 'blue'}>
        <LoaderWrap loading={loading}>
          <InformationComponent
            useAlternateGridUI={useAlternateGridUI}
            header={header}
            display={display}
            editButton={editButton}
          />
        </LoaderWrap>
      </Segment>
    </div>
  )
}
