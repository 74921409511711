import { Row } from 'react-table'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Customer, ExternalCustomer, InternalCustomer } from '../../../actions/NewCustomers'
import { createMemoizedColumns } from '../../../components/TableComponents/ReactTable'
import { CustomersService } from '../services/CustomersService'
import {
  getValuesFromDynamoQuery,
  InfinitePaginatedTable
} from '../../../components/TableComponents/InfinitePaginatedTable'
import { formatDate } from '../../../utils/dateUtils'
import { RegionFlagPopup } from '../../../utils/regionFlags'

export const NewCustomersList = ({
  customersService,
  isKeywordSearch
}: {
  customersService: CustomersService
  isKeywordSearch: boolean
}) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = customersService
  const customerColumns = createMemoizedColumns<Customer>([
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
        <Link to={`/customers/${row.original.id}`}>{row.original.name}</Link>
      )
    },
    {
      Header: 'Salesforce ID',
      accessor: 'salesforceId',
      Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) =>
        row.original.salesforceId ? (
          <a
            href={`https://sked.lightning.force.com/lightning/r/Account/${row.original.salesforceId}/view`}
            target="_blank"
            rel="noreferrer"
          >
            {row.original.salesforceId}{' '}
            <span>
              <Icon name="external" />
            </span>
          </a>
        ) : (
          <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>
        )
    },
    {
      Header: 'Region',
      accessor: 'region',
      Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) =>
        row.original.region ? (
          <RegionFlagPopup region={row.original.region} />
        ) : (
          <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>
        )
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
        <>{formatDate(row.original.createdAt)}</>
      )
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
        <>{formatDate(row.original.updatedAt)}</>
      )
    }
  ])
  return (
    <InfinitePaginatedTable
      loading={isLoading}
      columns={customerColumns}
      data={data}
      emptyMessage={`No customers found ${isKeywordSearch ? '' : '(search is case sensitive)'}`}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      fetchingNextPage={isFetchingNextPage}
      getValuesFromData={getValuesFromDynamoQuery}
      scrollable
    />
  )
}
