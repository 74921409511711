import { Icon } from 'semantic-ui-react'

interface SortedHeaderCellProps {
  title: string
  name?: string
}

export const SortableHeaderCell =
  (sortColumn: string, sortDirection: 'ascending' | 'descending', onClick: (clickedColumn: string) => () => void) =>
  (props: SortedHeaderCellProps) => {
    const name = props.name ?? props.title.toLowerCase()
    return (
      <a
        onClick={onClick(name)}
        style={{ display: 'inline-flex', alignItems: 'baseline', color: 'inherit' }}
        data-testid="sortable-header"
        role="link"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onClick(name)()
          }
        }}
        tabIndex={0}
      >
        {props.title}
        <Icon
          name={sortColumn === name && sortDirection === 'descending' ? 'caret down' : 'caret up'}
          style={{ opacity: sortColumn === name ? '100%' : '20%' }}
        />
      </a>
    )
  }
