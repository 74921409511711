import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getPackageByName, IPackage, IPackageProperties } from '../../actions/Package'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useGetPackageQuery = (packageName: string): UseQueryResult<IPackage> =>
  useQuery(['package', packageName], () => getPackageByName(packageName), {
    onError: error => createErrorToast(error),
    enabled: !!packageName
  })

export const useGetPackagePropertiesQuery = (packageName: string): UseQueryResult<IPackageProperties> =>
  useQuery(['packageProperties', packageName], () => getPackageByName(packageName).then(p => p.properties), {
    onError: error => createErrorToast(error),
    enabled: !!packageName
  })
