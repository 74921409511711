import { useState } from 'react'
import { DropdownItemProps, DropdownProps, InputOnChangeData } from 'semantic-ui-react'

import { startCase, toLower } from 'lodash'
import { DynamoQueryFilter } from '../actions/Utils'
import { ISearch } from '../views/Utils'

export interface DynamoQueryFilterService {
  searchValue: ISearch
  searchOptions: DropdownItemProps[]
  handleSearchChange: (_: any, data: InputOnChangeData) => void
  handleSearchCategoryChange: (_: any, data: DropdownProps) => void
  dynamoQueryFilter: DynamoQueryFilter
}

const makeDynamoQuery = (
  searchValue: string,
  parameter: string,
  additionalFilter?: DynamoQueryFilter
): DynamoQueryFilter => {
  if (searchValue) {
    const searchFilter = `contains(\${${parameter}}, @{searchValue})`
    const additionalFilterString = additionalFilter?.filter ? ` AND ${additionalFilter?.filter}` : ''
    return {
      filter: searchFilter + additionalFilterString,
      filterKeys: { searchValue, ...(additionalFilter?.filterKeys || {}) }
    }
  }
  return additionalFilter || {}
}

const KEYWORD_SARCH_CATEGORY = '_keywordSearch'

export const useFilterService = (
  searchOptions: string[],
  defaultCategory: string,
  additionalFilter?: DynamoQueryFilter
): DynamoQueryFilterService => {
  const [searchCategory, setCategory] = useState<string>(defaultCategory)
  const [searchValue, setValue] = useState<string>('')
  const handleSearchChange = (_: any, data: InputOnChangeData) => setValue(data.value)
  const handleSearchCategoryChange = (_: any, data: DropdownProps) => {
    const value = data.value ? data.value.toString() : ''
    if (searchOptions.includes(value)) {
      setCategory(value)
    }
  }
  const searchOptionsDropdown = searchOptions.map(option => ({ text: startCase(option), value: option }))
  return {
    searchValue: {
      searchValue,
      searchCategory
    },
    handleSearchChange,
    handleSearchCategoryChange,
    searchOptions: searchOptionsDropdown,
    dynamoQueryFilter: makeDynamoQuery(
      searchCategory === 'keyword' ? toLower(searchValue) : searchValue,
      searchCategory === 'keyword' ? KEYWORD_SARCH_CATEGORY : searchCategory,
      additionalFilter
    )
  }
}
