import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'
import { IMobileV2Form, IUpdateShowInV3Payload, updateShowInMobileV3Flag } from '../actions/MobileV2Forms'

export const useMobileV2FormShowInV3Mutation = (tenantId: string, region: string) => {
  const queryClient = useQueryClient()
  return useMutation<IMobileV2Form[], AxiosError, IUpdateShowInV3Payload>(
    (payload: IUpdateShowInV3Payload) => updateShowInMobileV3Flag(payload, tenantId, region),
    {
      onSuccess: () => {
        createSuccessToast('Mobile forms v2 is updated successfully')
        queryClient.invalidateQueries(['mobileV2Form'])
      },
      onError: (error: AxiosError) => {
        createErrorToast(error, { title: 'Error in updating mobile form v2' })
      }
    }
  )
}
