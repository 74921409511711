import axios, { InternalAxiosRequestConfig } from 'axios'
import Env from '../env/Env'
import AuthClient from '../auth/AuthClient'

export const elasticServerConfig = {
  baseURL: Env.API,
  timeout: 60000
}

export const getToken = async () => {
  try {
    const token = await AuthClient.getTokenSilently()
    return token
  } catch (error: any) {
    // catch all for handling any errors fetching an existing access token from cache
    // requesting a new access token from a hidden iframe or
    // calling auth0 with a refresh token.
    await AuthClient.logout({ logoutParams: { returnTo: window.location.origin } })
    throw Error(error)
  }
}

export const serverInterceptor = (token?: string) => async (config: InternalAxiosRequestConfig<any>) => {
  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`)
    return config
  }
  const authToken = await getToken()

  if (authToken) {
    config.headers.setAuthorization(`Bearer ${authToken}`)
  }

  return config
}

export const regionalURL = (baseURL: string, region: string) => {
  const apiURLSegments = baseURL.split('.')
  const result = [apiURLSegments[0], region.toLowerCase(), ...apiURLSegments.slice(1, apiURLSegments.length)].join('.')
  return region.toLowerCase() === 'us' ? baseURL : result
}

export const regionalApiServer = (region: string, token?: string) => {
  const instance = axios.create({ ...elasticServerConfig, baseURL: regionalURL(Env.API, region) })
  // We need to intercept and apply auth token if not present in header to ensure token is retrieved from Auth0 Provider.
  instance.interceptors.request.use(serverInterceptor(token), error => Promise.reject(error))
  return instance
}

export const elasticserver = regionalApiServer('US')

/**
 * Admin API
 */
export const adminApiConfig = {
  baseURL: Env.ADMIN_API,
  timeout: 15000
}

export const adminApi = axios.create(adminApiConfig)
// We need to intercept and apply auth token if not present in header to ensure token is retrieved from Auth0 Provider.
adminApi.interceptors.request.use(
  async config => {
    const authToken = await getToken()

    if (authToken) {
      config.headers.setAuthorization(`Bearer ${authToken}`)
    }

    return config
  },
  error => Promise.reject(error)
)

/**
 * Auth Service
 */
export const authServiceConfig = {
  baseURL: Env.AUTH_SERVICE,
  timeout: 299000
}

export const authService = axios.create(authServiceConfig)
// We need to intercept and apply auth token if not present in header to ensure token is retrieved from Auth0 Provider.
authService.interceptors.request.use(
  async config => {
    const authToken = await getToken()
    if (authToken) {
      config.headers.setAuthorization(`Bearer ${authToken}`)
    }

    return config
  },
  error => Promise.reject(error)
)

export const customerServiceConfig = {
  baseURL: Env.CUSTOMER_SERVICE,
  timeout: 299000
}

export const customerService = axios.create(customerServiceConfig)
// We need to intercept and apply auth token if not present in header to ensure token is retrieved from Auth0 Provider.
customerService.interceptors.request.use(
  async config => {
    const authToken = await getToken()
    if (authToken) {
      config.headers.setAuthorization(`Bearer ${authToken}`)
    }

    return config
  },
  error => Promise.reject(error)
)
