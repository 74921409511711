import { Button, Divider, Icon, Input, Popup, Statistic } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { useState } from 'react'
import { LoaderWrap } from '../../../loadingComponents/LoaderWrap'
import { IUserSummary } from '../../../../actions/Tenants'
import { useUpdateTeamMutation } from '../../../../mutations/UpdateTeamMutation'

export const UserCountsBase = ({
  data,
  loading,
  labelPrefix,
  userLimitComponent
}: {
  data: IUserSummary[]
  loading: boolean
  labelPrefix: string
  userLimitComponent?: JSX.Element
}) => (
  <LoaderWrap loading={loading}>
    <div data-testid="user-summary">
      <Statistic.Group size="small" className="ui stackable middle aligned three column grid">
        <Statistic horizontal className="column">
          <Statistic.Value>{data[0].totalActiveResources}</Statistic.Value>
          <Statistic.Label>
            {labelPrefix} Resources{' '}
            <Popup
              trigger={<Icon name="question circle" />}
              content={
                <div>
                  A user is counted as a Resource if they are active, have the &apos;Resource&apos; role assigned, and{' '}
                  <b>don&apos;t have access to ANY</b> of the following permissions via their allocated user roles:
                  <ul>
                    <li>Manage resource allocations</li>
                    <li>Dispatch allocated work to resources</li>
                    <li>Create and send job and shift offers</li>
                  </ul>
                </div>
              }
              basic
              wide
            />
          </Statistic.Label>
        </Statistic>
        <Statistic horizontal className="column">
          <Statistic.Value>{data[0].totalActiveSchedulers}</Statistic.Value>
          <Statistic.Label>
            {labelPrefix} Schedulers{' '}
            <Popup
              trigger={<Icon name="question circle" />}
              content={
                <div>
                  A user is counted as a Scheduler if they are active and <b>have access to ANY</b> of the following
                  permissions via their allocated user roles:
                  <ul>
                    <li>Manage resource allocations</li>
                    <li>Dispatch allocated work to resources</li>
                    <li>Create and send job and shift offers</li>
                  </ul>
                  Users with the Admin role applied are always counted as a Scheduler.
                </div>
              }
              basic
              wide
            />
          </Statistic.Label>
        </Statistic>
        {userLimitComponent}
      </Statistic.Group>
      <Divider />
    </div>
  </LoaderWrap>
)

export const UserCounts = ({
  data,
  loading,
  hideLimitForTeam,
  isForCustomer,
  metadata,
  teamId
}: {
  data: IUserSummary[]
  loading: boolean
  hideLimitForTeam?: boolean
  isForCustomer?: boolean
  metadata?: Record<string, any>
  teamId?: string
}) => {
  const labelPrefix = isForCustomer ? 'Active Production' : 'Active'
  const MaxActiveUsersEnforced: number | undefined = metadata?.MaxActiveUsersEnforced
  const { value: isEdit, toggle: toggleEditing } = useBoolean(false)
  const [editedLimit, setEditedLimit] = useState<string>()
  const { mutate: updateTeam } = useUpdateTeamMutation(teamId!)(() => null)

  if (hideLimitForTeam || isForCustomer) {
    return <UserCountsBase data={data} loading={loading} labelPrefix={labelPrefix} />
  }

  const save = async () => {
    if (editedLimit !== undefined) {
      updateTeam({
        metadata: { ...metadata, MaxActiveUsersEnforced: parseInt(editedLimit, 10) }
      })
    }
    toggleEditing()
  }

  const editCancel = () => {
    setEditedLimit(undefined)
    toggleEditing()
  }

  const userLimitComponent = (
    <Statistic horizontal className="right aligned column">
      <Statistic.Label>
        <Popup
          trigger={<Icon name="question circle" />}
          content={
            <div>
              Active user limits can be set by clicking the <b>Edit</b> button here or by navigating to the{' '}
              <b>Metadata</b> tab and adding a new property with the following values:
              <ul>
                <li>
                  <b>Value:</b> MaxActiveUsersEnforced
                </li>
                <li>
                  <b>Type:</b> Number
                </li>
              </ul>
              When set, users will be notified of their limit via an alert banner on the <b>Users</b> list. They will
              see an error, and will be unable to create or reactivate users if their limit has been exceeded
            </div>
          }
          basic
          wide
          hoverable
        />
        Active User limit:
      </Statistic.Label>
      <Statistic.Value className="small">
        {isEdit ? (
          <Input
            size="mini"
            placeholder="Set limit"
            value={editedLimit ?? MaxActiveUsersEnforced ?? ''}
            onChange={(_, { value }) => setEditedLimit(value)}
            type="number"
          />
        ) : (
          (MaxActiveUsersEnforced ?? 'Not set')
        )}{' '}
        <Button className="sked-blue-button" size="mini" onClick={editCancel} content={isEdit ? 'Cancel' : 'Edit'} />
        {isEdit && <Button className="sked-blue-button" primary size="mini" onClick={_ => save()} content={'Set'} />}
      </Statistic.Value>
    </Statistic>
  )

  return (
    <UserCountsBase data={data} loading={loading} labelPrefix={labelPrefix} userLimitComponent={userLimitComponent} />
  )
}
