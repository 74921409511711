import { Grid } from 'semantic-ui-react'
import { useCustomerTeamsService } from '../services/CustomerTeamsService'
import { NewTeamsList } from '../../newTeams/component/NewTeamsList'
import CreateTeamForm from '../../newTeams/component/CreateTeam/CreateTeamForm'
import CreateNelxTeamForm from '../../newTeams/component/CreateTeam/CreateNelxTeamForm'
import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import { showCreateNelxTeamButton } from '../../../views/newTeams/services/CreateNelxTeamService'

export const CustomerTeamsList = ({ customerId }: { customerId: string }) => {
  const customerTeamsService = useCustomerTeamsService(customerId)({})
  const { data: customer } = useGetCustomerQuery(customerId)

  return (
    <>
      <Grid stackable={true} columns={1} verticalAlign="middle">
        <Grid.Column textAlign="right">
          {showCreateNelxTeamButton(customer, window.location.hostname) && (
            <CreateNelxTeamForm
              buttonText="Create NeLX Team"
              title={`Create a new team for: ${customer?.name} (New login system) `}
              suppliedCustomerId={customerId}
              suppliedCustomerName={customer?.name}
              suppliedCustomerRegion={customer?.region}
            />
          )}
          {customer?.id && (
            <CreateTeamForm
              buttonText="Create Team"
              title={`Create a new team for: ${customer?.name}`}
              suppliedCustomerId={customerId}
              suppliedCustomerName={customer?.name}
              suppliedCustomerRegion={customer?.region}
            />
          )}
        </Grid.Column>
      </Grid>
      <NewTeamsList teamsService={customerTeamsService} showCustomerId={false} isKeywordSearch={false} />
    </>
  )
}
