import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IPackageProperties, updatePackageProperties } from '../../actions/Package'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'

export const useUpdatePackagePropertiesMutation = (packageName: string) => (onSaveFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<IPackageProperties, AxiosError, Partial<IPackageProperties>>(
    (patchProperties: Partial<IPackageProperties>) => updatePackageProperties(packageName)(patchProperties),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['packageProperties', packageName])
        onSaveFn()
        createSuccessToast('Package properties updated successfully.')
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
