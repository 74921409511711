import { Row } from 'react-table'
import { DetailedAuthConnection } from '../../actions/NewTeams'
import AddLoginModal from '../../views/newTeams/component/AddLoginModal'
import { LoginConnectionsService } from '../../views/newTeams/services/LoginConnectionsService'
import { NewDeleteModal } from '../../views/modalComponents/NewDeleteModal'
import HeaderWithControl from './BaseTableComponents/HeaderWithControl'
import ReorderLoginControls from './LoginConnectionsTableComponents/ReorderLoginControls'
import { createMemoizedColumns, MemoizedColumns, Table } from './ReactTable'

const LoginConnectionsTable = ({
  tableTitle,
  loginConnections,
  isLoading,
  isUpdating,
  isCreating,
  isDeleting,
  newLoginConnection,
  vendorOptions,
  validEndpoint,
  validConnectionName,
  validLoginConnection,
  createLoginConnection,
  handleConnectionInput,
  reset,
  reorderConnections,
  deleteLoginConnection
}: LoginConnectionsService) => {
  const isLoadingTable = isLoading || isUpdating || isCreating || isDeleting
  const columns: MemoizedColumns<DetailedAuthConnection> = createMemoizedColumns<DetailedAuthConnection>(
    [
      { Header: 'Login Button Text', accessor: 'displayName' },
      { Header: 'Connection Name', accessor: 'name' },
      { Header: 'Endpoint', accessor: 'tokenURL' },
      {
        Header: 'Re-Order',
        Cell: ({ row }: { row: Row<DetailedAuthConnection> }) => (
          <ReorderLoginControls
            handleReorder={reorderConnections}
            index={row.index}
            disabled={loginConnections.length <= 1 || isLoadingTable}
          />
        )
      },
      {
        Header: 'Delete',
        Cell: ({ row }: { row: Row<DetailedAuthConnection> }) => (
          <NewDeleteModal
            deleteMethod={() => deleteLoginConnection(row.cells[1].value)}
            type="Login Connection"
            content={`Are you sure you want to delete the ${row.cells[1].value} login connection?`}
            isDeleting={isDeleting}
            disabled={isLoadingTable}
          />
        )
      }
    ],
    [loginConnections, isUpdating, isDeleting]
  )

  return (
    <div data-testid="login-connections">
      <HeaderWithControl
        title={tableTitle}
        size="h3"
        control={
          <AddLoginModal
            handleChange={handleConnectionInput}
            newLoginConnection={newLoginConnection}
            vendorOptions={vendorOptions}
            reset={reset}
            handleCreate={createLoginConnection}
            loading={isCreating}
            disabled={isLoading || isCreating}
            validEndpoint={validEndpoint}
            validConnectionName={validConnectionName}
            validLoginConnection={validLoginConnection}
          />
        }
      />
      <Table<DetailedAuthConnection>
        columns={columns}
        data={loginConnections}
        loading={isLoadingTable}
        emptyMessage="Login Connections not found"
        color="blue"
        celled
      />
    </div>
  )
}

export default LoginConnectionsTable
