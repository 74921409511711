import { useSearchParams } from 'react-router-dom'
import { Button, List } from 'semantic-ui-react'
import { useCallback, useState } from 'react'
import { FilterModal } from './FilterModal'
import { Filter } from './Filter'

export interface FilterCategoryOptions {
  text: string
  values?: string[]
  hideExactMatch?: boolean
}

export type FilterCategories = Map<string, FilterCategoryOptions>

interface FilterProps {
  filterCategories: FilterCategories
}

export enum FilterMatch {
  EXACT,
  CONTAINS
}

export interface ActiveFilter {
  category: string
  match: FilterMatch
  value: string
}

export const filterMatches = (filter: ActiveFilter, value?: string, caseSensitive?: boolean) => {
  if (!value) {
    return false
  }
  const caseCorrect = (str: string) => (caseSensitive ? str : str.toLowerCase())

  switch (filter.match) {
    case FilterMatch.EXACT:
      return caseCorrect(value) === caseCorrect(filter.value)
    case FilterMatch.CONTAINS:
      return caseCorrect(value).includes(caseCorrect(filter.value))
  }
}

export const filtersMatchAny = (filters: ActiveFilter[], value?: string, caseSensitive?: boolean) =>
  !filters.every(filter => !filterMatches(filter, value, caseSensitive))

const paramToFilter = (category: string, value: string) => {
  if (value.startsWith('~')) {
    return { category, value: value.substring(1), match: FilterMatch.CONTAINS }
  } else {
    return { category, value, match: FilterMatch.EXACT }
  }
}

const filterToParam = (filter: ActiveFilter): string => {
  switch (filter.match) {
    case FilterMatch.CONTAINS:
      return `~${filter.value}`
    default:
      return filter.value
  }
}

export const filterEquals = (left: ActiveFilter, right?: ActiveFilter) =>
  right && left.category === right.category && left.match === right.match && left.value === right.value

export const searchParamsToFilters = (searchParams: URLSearchParams, filterCategories: FilterCategories) => {
  const filters: ActiveFilter[] = []
  searchParams.forEach((vs, k) => {
    if (Array.from(filterCategories.keys()).includes(k)) {
      for (const v of vs.split(',')) {
        filters.push(paramToFilter(k, v))
      }
    }
  })
  return filters
}

export const Filters = (props: FilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [category, setCategory] = useState('')
  const [value, setValue] = useState('')
  const [match, setMatch] = useState(FilterMatch.EXACT)
  const [previousFilter, setPreviousFilter] = useState<ActiveFilter | undefined>(undefined)

  const filters = searchParamsToFilters(searchParams, props.filterCategories)

  const deleteFilter = (deletedFilter: ActiveFilter) => () => {
    const currentFilters = filters.filter(existing => existing.category === deletedFilter.category)
    switch (currentFilters.length) {
      case 0:
        // this should not happen
        return
      case 1:
        setSearchParams(currentSearchParams => {
          currentSearchParams.delete(deletedFilter.category)
          return currentSearchParams
        })
        return
      default:
        setSearchParams(currentSearchParams => {
          const updatedValues = currentFilters
            .filter(currentFilter => !filterEquals(currentFilter, deletedFilter))
            .map(filterToParam)
            .join(',')
          currentSearchParams.set(deletedFilter.category, updatedValues)
          return currentSearchParams
        })
    }
  }

  const addFilter = (addedFilter: ActiveFilter) => {
    const currentFilters = filters.filter(existing => existing.category === addedFilter.category)
    switch (currentFilters.length) {
      case 0:
        setSearchParams(currentSearchParams => {
          currentSearchParams.set(addedFilter.category, filterToParam(addedFilter))
          return currentSearchParams
        })
        return
      default:
        setSearchParams(currentSearchParams => {
          currentFilters.push(addedFilter)
          const updatedValues = currentFilters.map(filterToParam).join(',')
          currentSearchParams.set(addedFilter.category, updatedValues)
          return currentSearchParams
        })
    }
  }

  const updateFilter = (updatedFilter: ActiveFilter, oldFilter: ActiveFilter) => {
    const currentFilters = filters.filter(existing => existing.category === updatedFilter.category)
    switch (currentFilters.length) {
      case 0:
        // this should not happen
        return
      default:
        setSearchParams(currentSearchParams => {
          currentFilters.push(updatedFilter)
          const updatedValues = currentFilters
            .filter(currentFilter => !filterEquals(currentFilter, oldFilter))
            .map(filterToParam)
            .join(',')
          currentSearchParams.set(updatedFilter.category, updatedValues)
          return currentSearchParams
        })
    }
  }

  const openModalWith = (filter: ActiveFilter) => () => {
    setCategory(filter.category)
    setMatch(filter.match)
    setValue(filter.value)
    setPreviousFilter(filter)
    setIsModalOpen(true)
  }

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [setIsModalOpen])

  return (
    <>
      <FilterModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        category={category}
        setCategory={setCategory}
        value={value}
        setValue={setValue}
        match={match}
        setMatch={setMatch}
        categories={props.filterCategories}
        filters={filters}
        previousFilter={previousFilter}
        setPreviousFilter={setPreviousFilter}
        updateFilter={previousFilter ? updateFilter : addFilter}
      />
      Filters:{' '}
      <List horizontal aria-label="Manage filters">
        {filters.map(filter => (
          <Filter
            onClick={openModalWith(filter)}
            onDelete={deleteFilter(filter)}
            filter={filter}
            key={`${filter.value}-${filter.category}`}
          />
        ))}
        <Button basic color="green" icon="plus" onClick={openModal} aria-label="Add filter" />
      </List>
    </>
  )
}
