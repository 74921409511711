import 'prismjs/themes/prism-solarizedlight.css'
import './styles/utils.scss'
import './index.css'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import { AuthStoreProvider } from './context/AuthContext'
import { initDataDogRum } from './analytics/instrumentation'
import { BrowserRouterV7 } from './router/BrowserRouterV7'
initDataDogRum()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <BrowserRouterV7>
    <AuthStoreProvider>
      <App />
    </AuthStoreProvider>
  </BrowserRouterV7>
)
