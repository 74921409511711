import { Grid, Header, Table } from 'semantic-ui-react'
import { startCase } from 'lodash'
import { ReactElement } from 'react'

export interface EditableDisplayFieldBaseProps {
  fieldName: string
  headerTitle?: string
  editable: boolean
  DisplayComponent: ReactElement
  EditComponent: ReactElement
  showField: (editable: boolean) => boolean
  isEditableTable?: boolean
  useAlternateGridUI?: boolean
}

export const EditableDisplayBaseField = ({
  headerTitle,
  fieldName,
  editable,
  DisplayComponent,
  EditComponent,
  showField,
  isEditableTable = false,
  useAlternateGridUI
}: EditableDisplayFieldBaseProps) => {
  if (showField(editable)) {
    if (isEditableTable) {
      return (
        <Table.Row key="EditableTeamDisplayBaseField" className="feature">
          <Table.Cell style={{ backgroundColor: '#f9fafb', paddingLeft: '10px' }}>
            <Header size="tiny"> {headerTitle || startCase(fieldName)}</Header>
          </Table.Cell>
          <Table.Cell style={{ padding: '10px' }}>{editable ? EditComponent : DisplayComponent}</Table.Cell>
        </Table.Row>
      )
    } else if (useAlternateGridUI) {
      return (
        <Grid.Row key="EditableTeamDisplayBaseField" className="feature">
          <Grid.Column width={6} verticalAlign="middle">
            <Header as="h5"> {headerTitle ?? startCase(fieldName)}</Header>
          </Grid.Column>
          <Grid.Column width={10} verticalAlign="middle" data-testid="information-cell">
            {editable ? EditComponent : DisplayComponent}
          </Grid.Column>
        </Grid.Row>
      )
    } else {
      return (
        <>
          <Header size="tiny">{headerTitle || startCase(fieldName)}:</Header>
          {editable ? EditComponent : DisplayComponent}
        </>
      )
    }
  }
  return <></>
}
