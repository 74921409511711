import { drop, isNumber, sortBy, take } from 'lodash'
import * as React from 'react'
import { PaginationProps } from 'semantic-ui-react'
import { ISearch } from '../Utils'

export const handlePaginationChange =
  (setPage: (page: number) => void) => (e: React.SyntheticEvent<HTMLElement>, data: PaginationProps) => {
    const activePageData = data.activePage
    if (typeof activePageData === 'string') {
      const activePage: number = parseInt(activePageData, 10)
      setPage(activePage)
    } else if (typeof activePageData === 'number') {
      setPage(activePageData)
    } else {
      throw Error('Pagination Props Type Error')
    }
  }

export const getDisplayedData = (data: any[], pageNumber: number, numberPerPage: number) =>
  take(drop(data, numberPerPage * (pageNumber - 1)), numberPerPage)

export const filterData = <T extends Record<string, any>>(searchValue: ISearch, data?: T[]) => {
  const searchLower = searchValue.searchValue.toLowerCase()
  if (data && data.length > 0 && searchValue.searchValue) {
    if (searchValue.searchCategory in data[0]) {
      return data.filter(obj => testSearch(searchLower, obj[searchValue.searchCategory]))
    }
    return data.filter(obj => allFilter(Object.values(obj), searchLower))
  }
  return data || []
}

const allFilter = (values: any[], searchLower: string) =>
  values.reduce((doesContain, value) => doesContain || testSearch(searchLower, value), false)

const testSearch = (searchValue: string, value?: any) => {
  if (typeof value === 'object') {
    return JSON.stringify(value).toLowerCase().includes(searchValue)
  }
  return value?.toString().toLowerCase().includes(searchValue)
}

export const sortData = (data: any[], sortColumn: string, sortDirection: string) => {
  if (data.length > 0 && sortColumn in data[0]) {
    const sorted = sortBy(data, obj =>
      isNumber(obj[sortColumn]) ? obj[sortColumn] : obj[sortColumn]?.toString().toLowerCase()
    )
    if (sortDirection === 'descending') {
      sorted.reverse()
    }
    return sorted
  }
  return data
}
