import * as React from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { ActiveFilter, FilterCategories, filterEquals, FilterMatch } from './Filters'

export interface FilterModalProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  category: string
  setCategory: React.Dispatch<React.SetStateAction<string>>
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  match: FilterMatch
  setMatch: React.Dispatch<React.SetStateAction<FilterMatch>>
  categories: FilterCategories
  updateFilter(updated: ActiveFilter, previous?: ActiveFilter): void
  previousFilter?: ActiveFilter
  setPreviousFilter: React.Dispatch<React.SetStateAction<ActiveFilter | undefined>>
  filters: ActiveFilter[]
}

export const FilterModal = (props: FilterModalProps) => {
  const title = props.previousFilter ? 'Update filter' : 'Add filter'

  const resetModal = () => {
    props.setCategory('')
    props.setMatch(FilterMatch.EXACT)
    props.setValue('')
    props.setIsModalOpen(false)
    props.setPreviousFilter(undefined)
  }

  const currentCategory = props.categories.get(props.category)
  if (currentCategory?.hideExactMatch) {
    props.setMatch(FilterMatch.CONTAINS)
  }

  const categoryOptions = Array.from(props.categories.entries())
    .map(([key, value]) => ({ value: key, text: value.text }))
    .sort((a, b) => a.text.localeCompare(b.text))

  return (
    <Modal open={props.isModalOpen} onClose={resetModal} closeIcon size="tiny" aria-label={title}>
      <Modal.Header id="modal-header-filter">{title}</Modal.Header>
      <Modal.Content id="modal-content-filter">
        <Form
          onSubmit={() => {
            props.updateFilter(
              {
                category: props.category,
                match: props.match,
                value: props.value
              },
              props.previousFilter
            )
            resetModal()
          }}
        >
          <Form.Dropdown
            label={<label htmlFor="category">Category</label>}
            placeholder="Category"
            options={categoryOptions}
            value={props.category}
            id="category"
            selection
            onChange={(_e, data) => {
              props.setCategory(data.value as string)
            }}
          />
          {currentCategory?.values ? (
            <Form.Dropdown
              label={<label htmlFor="value">Value</label>}
              options={(currentCategory.values as string[]).map(value => ({
                text: value,
                value: value.toLowerCase()
              }))}
              value={props.value}
              id="value"
              selection
              search
              onChange={(_e, data) => {
                props.setValue(data.value as string)
              }}
            />
          ) : (
            <Form.Input
              label={<label htmlFor="value">Value</label>}
              name="value"
              id="value"
              placeholder="search value"
              onChange={(_e, data) => {
                props.setValue(data.value)
              }}
              value={props.value}
            />
          )}
          <Form.Group inline>
            <Form.Checkbox
              key="exact match"
              label={<label htmlFor="exact_match">Exact match</label>}
              id="exact_match"
              checked={props.match === FilterMatch.EXACT}
              onClick={(_event, data) => {
                props.setMatch(data.checked ? FilterMatch.EXACT : FilterMatch.CONTAINS)
              }}
              disabled={currentCategory?.hideExactMatch}
            />
          </Form.Group>

          <Button
            id="modal-button-update-filter"
            type="submit"
            fluid={true}
            disabled={
              props.value === '' ||
              props.category === '' ||
              (props.filters.length > 0 &&
                props.filters.every(filter =>
                  filterEquals(filter, { category: props.category, match: props.match, value: props.value })
                ))
            }
            className="form-button-sked-blue"
            content={title}
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}
