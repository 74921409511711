import * as _ from 'lodash'
import * as React from 'react'
import {
  CheckboxProps,
  Form,
  FormInputProps,
  Header,
  Input,
  InputOnChangeData,
  InputProps,
  Label,
  Select,
  SemanticWIDTHS
} from 'semantic-ui-react'
import { SyntheticEvent } from 'react'
import { IRegionInfo } from '../../actions/Regions'

interface IInputProps {
  width?: SemanticWIDTHS
  error: boolean
  onChange(event: React.SyntheticEvent<HTMLElement>, data: InputProps): void
  value: string
  labelSelector?: boolean
  labelMessage?: string
  loading?: boolean
  style?: any
}

interface IInputWithErrorProps extends IInputProps {
  id?: string
  required?: boolean
  label?: string
  disabled?: boolean
  placeholder: string
  name: string
  instanceField?: boolean
  labelPosition?: 'left' | 'right' | 'left corner' | 'right corner'
  labelPointing?: 'left' | 'right'
  fluid?: boolean
  type?: string
}

interface IBasicInput {
  width?: SemanticWIDTHS
  error?: boolean
  required?: boolean
  onChange(event: React.SyntheticEvent<HTMLElement>, data: InputProps): void
  value: string
  label: string
}

const getNameFromLabel = (label: string): string => {
  const name = label.replace(/\s+/g, '')
  return name.charAt(0).toLowerCase() + name.slice(1)
}

export const InputWithError = (props: IInputWithErrorProps) => (
  <Form.Field
    style={props.style}
    width={props.width}
    required={props.required}
    error={props.error}
    disabled={props.disabled}
  >
    {props.label && <label>{props.label}</label>}
    <Input
      id={props.id}
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      labelPosition={props.labelPosition}
      required={props.required}
      loading={props.loading}
      fluid={props.fluid}
      type={props.type}
    >
      {props.instanceField && <Label content="https://" />}
      <input />
      {props.loading && <i aria-hidden="true" className="spinner icon" />}
    </Input>
    {props.labelSelector && (
      <Label pointing={props.labelPointing || true} color="red">
        {props.labelMessage}
      </Label>
    )}
  </Form.Field>
)

export const TeamNameInput = (props: IInputProps) => (
  <InputWithError width="12" name="teamName" label="Team Name" placeholder="Team Name" required {...props} />
)

export const RegionInput = (props: { regions: IRegionInfo[] } & FormInputProps) => (
  <Form.Input
    control={Select}
    width="4"
    required={props.required}
    label={props.label || 'Region'}
    name={props.name || 'region'}
    options={props.regions.map(region => ({ key: region.region, value: region.region, text: region.name }))}
    onChange={props.onChange}
    {...props}
  />
)

export const BasicInput = (props: IBasicInput) => (
  <Form.Input
    width={props.width}
    required={props.required}
    label={props.label}
    placeholder={props.label}
    name={getNameFromLabel(props.label)}
    value={props.value}
    onChange={props.onChange}
  />
)

export interface IDetailsInput {
  editable: boolean
  value?: string
  name: string
  title?: string
  onChange: (event: React.SyntheticEvent, data: InputOnChangeData) => void
  editedValue?: string
}

export const DetailsInput = (props: IDetailsInput) => (
  <React.Fragment>
    {props.editable ? (
      <Form.Input
        label={<label htmlFor={props.name}>{props.title || _.startCase(props.name)}</label>}
        onChange={props.onChange}
        placeholder={props.title || _.startCase(props.name)}
        name={props.name}
        id={props.name}
        value={props.editedValue}
      />
    ) : (
      <>
        <Header size="tiny">{props.title || _.startCase(props.name)}</Header>
        <p>{props.value}</p>
      </>
    )}
  </React.Fragment>
)

export interface IDetailsCheckbox {
  checked?: boolean
  onClick: (event: SyntheticEvent, data: CheckboxProps) => void
  editable: boolean
  title: string
  name: string
}

export const DetailsCheckbox = (props: IDetailsCheckbox) => (
  <Form.Checkbox
    checked={props.checked}
    onClick={props.onClick}
    disabled={!props.editable}
    name={props.name}
    id={props.name}
    label={<label htmlFor={props.name}>{props.title}</label>}
  />
)
