import { useMobileV2FormShowInV3Mutation } from '../../../mutations/MobileV2FormShowInV3Mutation'
import { IMobileV2Form, IUpdateShowInV3Payload } from '../../../actions/MobileV2Forms'
import { useGetMobileV2FormListQuery } from '../../../queries/GetMobileV2FormListQuery'

export interface MobileV2FormServiceConfig {
  tenantId: string
  region: string
}

export interface MobileV2FormService {
  mobileV2Forms: IMobileV2Form[]
  isFormListLoading: boolean
  isUpdateShowInV3FlagLoading: boolean
  updateShowInV3FlagAsync: (newCustomerConfig: IUpdateShowInV3Payload) => Promise<IMobileV2Form[]>
}

export const useMobileV2FormService = ({ tenantId, region }: MobileV2FormServiceConfig): MobileV2FormService => {
  const { data: mobileV2Forms, isLoading: isFormListLoading } = useGetMobileV2FormListQuery(tenantId, region)
  const { mutateAsync: updateShowInV3FlagAsync, isLoading: isUpdateShowInV3FlagLoading } =
    useMobileV2FormShowInV3Mutation(tenantId, region)

  return {
    mobileV2Forms: mobileV2Forms ?? [],
    updateShowInV3FlagAsync,
    isFormListLoading,
    isUpdateShowInV3FlagLoading
  }
}
