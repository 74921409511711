import { Grid, Header, Segment, Table } from 'semantic-ui-react'
import { EditableRecordInformationService } from '../../../services/EditableRecordInformationService'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { renderEditButton } from '../../../components/InformationComponents/EditableInformation'
import { renderEditableDisplay } from '../../../components/InformationComponents/InformationComponent'

export const EditableTable = <T extends object>({
  title,
  recordName,
  loading,
  editing,
  submitting,
  fields,
  saveEdits,
  editedData,
  editIsValid,
  toggleEditing
}: EditableRecordInformationService<T>) => (
  <div>
    <Grid>
      <Grid.Column floated="left" width={5} textAlign="left" verticalAlign="middle">
        <Header>{title}</Header>
      </Grid.Column>
      <Grid.Column floated="right" width={5} textAlign="right">
        {renderEditButton(editing, submitting, saveEdits, editedData, editIsValid, toggleEditing)}
      </Grid.Column>
    </Grid>
    <Segment color="blue" style={{ flexGrow: 0, padding: '0px' }} data-testid={`${recordName}-details`}>
      <Table celled basic="very" stackable size="small">
        <TableLoaderWrap loading={loading} array={fields} emptyMessage="test">
          <Table.Body>{renderEditableDisplay(fields, editing, true)}</Table.Body>
        </TableLoaderWrap>
      </Table>
    </Segment>
  </div>
)
