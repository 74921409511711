import { Grid, Header, Breadcrumb, BreadcrumbDivider, BreadcrumbSection } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { useBoolean } from 'usehooks-ts'
import { useGetPackageVersionByQuery } from '../../queries/packages/GetPackageVersionByQuery'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetPackageTenantCountQuery } from '../../queries/packages/GetPackageTenantCountQuery'
import { DisplayOnlyInformation } from '../../components/InformationComponents/DisplayOnlyInformation'
import { IPackageVersionWithTenantCount } from '../../actions/Package'
import { useDisplayOnlyRecordInformationService } from '../../services/EditableRecordInformationService'
import { PackageVersionArtifactsModal } from './component/PackageVersionArtifactsModal'
import {
  combinePackageVersionWithTenantCount,
  generatePackageVersionTenantQuery
} from './services/PackageTenantCountUtils'
import { PackageTenantsList } from './component/PackageTenantsList'
import { getPackageVersionDetailsFields } from './services/PackageInformationFields'

export const PackageVersionsDetails = () => {
  const { value: modalIsOpen, toggle: toggleModalIsOpen } = useBoolean(false)

  const params = useParams()

  const packageName = params.id!
  const version = params.version!

  const { data: regionsInfo } = useGetRegionQuery()
  const { data: packageVersion, isLoading: isLoadingPackageVersion } = useGetPackageVersionByQuery(packageName, version)
  const { data: packageTenantCount, isLoading: isLoadingPackageVersionTenantCount } = useGetPackageTenantCountQuery(
    regionsInfo || [],
    generatePackageVersionTenantQuery(packageVersion)
  )

  const packageVersionWithTenantCount = combinePackageVersionWithTenantCount(packageVersion, packageTenantCount || [])
  const regionCodes = regionsInfo?.map(regionInfo => regionInfo.region.toUpperCase())

  const versionDetailsInformation = useDisplayOnlyRecordInformationService<IPackageVersionWithTenantCount>(
    'Package version details',
    packageVersionWithTenantCount!,
    getPackageVersionDetailsFields(toggleModalIsOpen),
    isLoadingPackageVersion || isLoadingPackageVersionTenantCount
  )

  return (
    <div className="route-component packages">
      <PackageVersionArtifactsModal
        modalIsOpen={modalIsOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        artifacts={packageVersionWithTenantCount?.artifacts || []}
      />
      <Grid stackable>
        <Grid.Column floated="left" width={5} textAlign="left">
          <Breadcrumb>
            <BreadcrumbSection href="/packages">Packages</BreadcrumbSection>
            <BreadcrumbDivider />
            <BreadcrumbSection href={`/packages/${encodeURIComponent(packageName)}`}>{packageName}</BreadcrumbSection>
          </Breadcrumb>
          <Header as="h2">
            {packageName} - {version}
          </Header>
        </Grid.Column>
      </Grid>
      <Grid stackable>
        <Grid.Column width={4}>
          <DisplayOnlyInformation<IPackageVersionWithTenantCount> {...versionDetailsInformation} />
        </Grid.Column>
        <Grid.Column width={12}>
          <PackageTenantsList
            regionCodes={regionCodes}
            title="Teams"
            packageName={packageName}
            packageVersion={version}
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}
