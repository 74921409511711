import { ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'

export const BrowserRouterV7 = (props: { children: ReactElement }) => (
  <BrowserRouter
    future={{
      v7_relativeSplatPath: true,
      v7_startTransition: true
    }}
  >
    {props.children}
  </BrowserRouter>
)
